var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card-text", [
        _c(
          "div",
          [
            _c(
              "ValidationObserver",
              { ref: "dsrFormValidation" },
              [
                _c(
                  "v-form",
                  { staticClass: "font-weight-bold font-size-sm" },
                  [
                    _c(
                      "v-container",
                      { attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", lg: "12", md: "12" },
                              },
                              [
                                _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      " KADAR NISBAH KHIDMAT HUTANG (DSR) : "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "16pt",
                                          color: "black",
                                          "white-spacing": "nowrap",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("fPercentage")(
                                                _vm.d_s_r.dsr_rate
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [
                                    _c("i", [
                                      _vm._v(
                                        " Formula DSR (%) = Jumlah Bayaran Balik Pembiayaan (A) "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("v-divider"),
                                _c("br"),
                                _c("br"),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", lg: "12", md: "12" },
                              },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      " A. Jumlah Bayaran Balik Pembiayaan "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", lg: "6", md: "6" },
                              },
                              [
                                _c(
                                  "h5",
                                  { staticStyle: { "font-weight": "600" } },
                                  [_vm._v("i. Pembiayaan Dalam Slip Gaji")]
                                ),
                                _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-bordered table-hover",
                                    },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "thead-dark" },
                                        [
                                          _c(
                                            "tr",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("#")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("Perkara")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("RM")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "tbody",
                                        { staticClass: "table-borderless" },
                                        [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("1")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Pembiayaan Perumahan (1)"
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_ai1_house1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai1_house1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai1_house1",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("2")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Pembiayaan Perumahan (2)"
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_ai2_house2,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai2_house2",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai2_house2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("3")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Pembiayaan Kenderaan (1)"
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.ex_ai3_car1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai3_car1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai3_car1",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("4")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Pembiayaan Kenderaan (2)"
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.ex_ai4_car2,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai4_car2",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai4_car2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("5")]
                                            ),
                                            _c("td", [
                                              _vm._v("Pembiayaan Koperasi (1)"),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.ex_ai5_kop1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai5_kop1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai5_kop1",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("6")]
                                            ),
                                            _c("td", [
                                              _vm._v("Pembiayaan Koperasi (2)"),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.ex_ai6_kop2,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai6_kop2",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai6_kop2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("7")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Lain-Lain (Dalam Slip Gaji)"
                                              ),
                                            ]),
                                            _c("td"),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_ai7a_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai7a_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai7a_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.ex_ai7a_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai7a_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai7a_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_ai7b_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai7b_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai7b_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.ex_ai7b_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai7b_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai7b_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_ai7c_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai7c_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai7c_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.ex_ai7c_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_ai7c_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_ai7c_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("tfoot", [
                                        _c(
                                          "tr",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "td",
                                              { attrs: { colspan: "2" } },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [_vm._v(" Jumlah: ")]
                                                ),
                                              ]
                                            ),
                                            _c("td", [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [
                                                  _c("vuetify-money", {
                                                    attrs: {
                                                      dense: _vm.moneyDense,
                                                      "hide-details":
                                                        _vm.moneyHideDetails,
                                                      readonly: true,
                                                      disabled: false,
                                                      outlined: false,
                                                      options: _vm.dsrOpt,
                                                      properties:
                                                        _vm.moneyDSRProperties,
                                                      valueWhenIsEmpty:
                                                        _vm.moneyWhenEmpty,
                                                    },
                                                    on: {
                                                      input: _vm.dsrCalculate,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.d_s_r.ex_ai_total,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.d_s_r,
                                                          "ex_ai_total",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "d_s_r.ex_ai_total",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", lg: "6", md: "6" },
                              },
                              [
                                _c(
                                  "h5",
                                  { staticStyle: { "font-weight": "600" } },
                                  [
                                    _vm._v(
                                      "ii. Pembiayaan Lain (Tiada dalam slip gaji)"
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-bordered table-hover",
                                    },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "thead-dark" },
                                        [
                                          _c(
                                            "tr",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("#")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("Perkara")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("RM")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "tbody",
                                        { staticClass: "table-borderless" },
                                        [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("1")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Pembiayaan Perumahan (1)"
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii1_house1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii1_house1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii1_house1",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("2")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Pembiayaan Perumahan (2)"
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii2_house2,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii2_house2",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii2_house2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("3")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Pembiayaan Kenderaan (1)"
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii3_car1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii3_car1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii3_car1",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("4")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Pembiayaan Kenderaan (2)"
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii4_car2,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii4_car2",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii4_car2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("5")]
                                            ),
                                            _c("td", [
                                              _vm._v("Pembiayaan Koperasi (1)"),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii5_kop1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii5_kop1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii5_kop1",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("6")]
                                            ),
                                            _c("td", [
                                              _vm._v("Pembiayaan Koperasi (2)"),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii6_kop2,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii6_kop2",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii6_kop2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("7")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Lain-Lain (Dalam Slip Gaji)"
                                              ),
                                            ]),
                                            _c("td"),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii7a_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii7a_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii7a_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii7a_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii7a_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii7a_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii7b_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii7b_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii7b_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii7b_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii7b_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii7b_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii7c_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii7c_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii7c_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .ex_aii7c_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "ex_aii7c_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.ex_aii7c_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("tfoot", [
                                        _c(
                                          "tr",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "td",
                                              { attrs: { colspan: "2" } },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [_vm._v(" Jumlah: ")]
                                                ),
                                              ]
                                            ),
                                            _c("td", [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [
                                                  _c("vuetify-money", {
                                                    attrs: {
                                                      dense: _vm.moneyDense,
                                                      "hide-details":
                                                        _vm.moneyHideDetails,
                                                      readonly: true,
                                                      disabled: false,
                                                      outlined: false,
                                                      options: _vm.dsrOpt,
                                                      properties:
                                                        _vm.moneyDSRProperties,
                                                      valueWhenIsEmpty:
                                                        _vm.moneyWhenEmpty,
                                                    },
                                                    on: {
                                                      input: _vm.dsrCalculate,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.d_s_r.ex_aii_total,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.d_s_r,
                                                          "ex_aii_total",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "d_s_r.ex_aii_total",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-6",
                                attrs: { cols: "12", lg: "12", md: "12" },
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "py-2 px-4" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "3",
                                          md: "3",
                                          sm: "3",
                                          xs: "3",
                                        },
                                      },
                                      [
                                        _c(
                                          "h5",
                                          {
                                            staticStyle: {
                                              "font-weight": "600",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "iii. Bayaran Balik Pembiayaan Baru"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "3",
                                          md: "3",
                                          sm: "3",
                                          xs: "3",
                                        },
                                      },
                                      [
                                        _c(
                                          "ValidationProvider",
                                          [
                                            _c("vuetify-money", {
                                              attrs: {
                                                dense: _vm.moneyDense,
                                                "hide-details":
                                                  _vm.moneyHideDetails,
                                                readonly: _vm.isMoneyReadOnly,
                                                disabled: _vm.moneyDisabled,
                                                outlined: _vm.moneyOutlined,
                                                options: _vm.dsrOpt,
                                                properties:
                                                  _vm.moneyDSRProperties,
                                                valueWhenIsEmpty:
                                                  _vm.moneyWhenEmpty,
                                              },
                                              on: { input: _vm.dsrCalculate },
                                              model: {
                                                value:
                                                  _vm.d_s_r.ex_aiii_inst_new,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.d_s_r,
                                                    "ex_aiii_inst_new",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "d_s_r.ex_aiii_inst_new",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "py-2 px-4" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "3",
                                          md: "3",
                                          sm: "3",
                                          xs: "3",
                                        },
                                      },
                                      [
                                        _c(
                                          "h5",
                                          {
                                            staticStyle: {
                                              "font-weight": "600",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Jumlah Bayaran Balik Pembiayaan"
                                            ),
                                            _c("br"),
                                            _vm._v("(i + ii + iii)"),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "3",
                                          md: "3",
                                          sm: "3",
                                          xs: "3",
                                        },
                                      },
                                      [
                                        _c(
                                          "ValidationProvider",
                                          [
                                            _c("vuetify-money", {
                                              attrs: {
                                                dense: _vm.moneyDense,
                                                "hide-details":
                                                  _vm.moneyHideDetails,
                                                readonly: true,
                                                disabled: false,
                                                outlined: false,
                                                options: _vm.dsrOpt,
                                                properties:
                                                  _vm.moneyDSRProperties,
                                              },
                                              on: { input: _vm.dsrCalculate },
                                              model: {
                                                value: _vm.d_s_r.ex_a_total,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.d_s_r,
                                                    "ex_a_total",
                                                    $$v
                                                  )
                                                },
                                                expression: "d_s_r.ex_a_total",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", lg: "6", md: "6" },
                              },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" B. Pendapatan ")]
                                ),
                                _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-bordered table-hover",
                                    },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "thead-dark" },
                                        [
                                          _c(
                                            "tr",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("#")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("Perkara")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("RM")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "tbody",
                                        { staticClass: "table-borderless" },
                                        [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("1")]
                                            ),
                                            _c("td", [_vm._v("Gaji Pokok")]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b1_sal_basic,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b1_sal_basic",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b1_sal_basic",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("2")]
                                            ),
                                            _c("td", [
                                              _vm._v("Elaun-Elaun Tetap"),
                                            ]),
                                            _c("td"),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b2a_allfixed_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b2a_allfixed_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b2a_allfixed_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b2a_allfixed_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b2a_allfixed_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b2a_allfixed_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b2b_allfixed_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b2b_allfixed_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b2b_allfixed_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b2b_allfixed_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b2b_allfixed_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b2b_allfixed_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b2c_allfixed_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b2c_allfixed_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b2c_allfixed_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b2c_allfixed_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b2c_allfixed_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b2c_allfixed_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b2d_allfixed_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b2d_allfixed_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b2d_allfixed_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b2d_allfixed_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b2d_allfixed_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b2d_allfixed_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b2e_allfixed_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b2e_allfixed_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b2e_allfixed_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b2e_allfixed_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b2e_allfixed_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b2e_allfixed_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("3")]
                                            ),
                                            _c("td", [
                                              _vm._v("Lain-Lain Elaun"),
                                            ]),
                                            _c("td"),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        hint: "Elaun Sara Hidup",
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b3a_allother_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b3a_allother_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b3a_allother_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b3a_allother_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b3a_allother_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b3a_allother_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b3b_allother_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b3b_allother_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b3b_allother_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b3b_allother_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b3b_allother_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b3b_allother_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b3c_allother_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b3c_allother_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b3c_allother_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b3c_allother_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b3c_allother_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b3c_allother_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b3d_allother_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b3d_allother_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b3d_allother_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b3d_allother_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b3d_allother_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b3d_allother_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b3e_allother_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b3e_allother_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b3e_allother_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b3e_allother_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b3e_allother_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b3e_allother_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("4")]
                                            ),
                                            _c("td", [
                                              _vm._v("Pendapatan Tambahan"),
                                            ]),
                                            _c("td"),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b4a_income_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b4a_income_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b4a_income_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b4a_income_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b4a_income_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b4a_income_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b4b_income_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b4b_income_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b4b_income_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b4b_income_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b4b_income_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b4b_income_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b4c_income_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b4c_income_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b4c_income_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b4c_income_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b4c_income_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b4c_income_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b4d_income_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b4d_income_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b4d_income_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b4d_income_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b4d_income_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b4d_income_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b4e_income_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b4e_income_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b4e_income_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r
                                                            .in_b4e_income_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_b4e_income_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_b4e_income_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("tfoot", [
                                        _c(
                                          "tr",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "td",
                                              { attrs: { colspan: "2" } },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [_vm._v(" Jumlah: ")]
                                                ),
                                              ]
                                            ),
                                            _c("td", [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [
                                                  _c("vuetify-money", {
                                                    attrs: {
                                                      dense: _vm.moneyDense,
                                                      "hide-details":
                                                        _vm.moneyHideDetails,
                                                      readonly: true,
                                                      disabled: false,
                                                      outlined: false,
                                                      options: _vm.dsrOpt,
                                                      properties:
                                                        _vm.moneyDSRProperties,
                                                      valueWhenIsEmpty:
                                                        _vm.moneyWhenEmpty,
                                                    },
                                                    on: {
                                                      input: _vm.dsrCalculate,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.d_s_r.in_b_total,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.d_s_r,
                                                          "in_b_total",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "d_s_r.in_b_total",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", lg: "6", md: "6" },
                              },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" C. Amaun Statutori ")]
                                ),
                                _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-bordered table-hover",
                                    },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "thead-dark" },
                                        [
                                          _c(
                                            "tr",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("#")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("Perkara")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("RM")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "tbody",
                                        { staticClass: "table-borderless" },
                                        [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("1")]
                                            ),
                                            _c("td", [_vm._v("KWSP")]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.in_c1_epf,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_c1_epf",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_c1_epf",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("2")]
                                            ),
                                            _c("td", [_vm._v("PERKESO")]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.in_c2_socso,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_c2_socso",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_c2_socso",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("3")]
                                            ),
                                            _c("td", [
                                              _vm._v("Cukai Pendapatan"),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.in_c3_pcb,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_c3_pcb",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_c3_pcb",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("4")]
                                            ),
                                            _c("td", [
                                              _vm._v("Zakat Pendapatan"),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.in_c4_zakat,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_c4_zakat",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_c4_zakat",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "row" },
                                              },
                                              [_vm._v("5")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                "Lain-Lain (Sila nyatakan)"
                                              ),
                                            ]),
                                            _c("td"),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.in_c5a_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_c5a_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_c5a_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.in_c5a_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_c5a_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_c5a_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.in_c5b_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_c5b_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_c5b_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.in_c5b_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_c5b_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_c5b_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("th", {
                                              staticClass: "text-center",
                                              attrs: { scope: "row" },
                                            }),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "ucase-text",
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        dense: "",
                                                        required: "",
                                                        readonly:
                                                          _vm.isDisabled,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.in_c5c_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_c5c_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_c5c_name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "ValidationProvider",
                                                  [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        dense: _vm.moneyDense,
                                                        "hide-details":
                                                          _vm.moneyHideDetails,
                                                        readonly:
                                                          _vm.isMoneyReadOnly,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        options: _vm.dsrOpt,
                                                        properties:
                                                          _vm.moneyDSRProperties,
                                                        valueWhenIsEmpty:
                                                          _vm.moneyWhenEmpty,
                                                      },
                                                      on: {
                                                        input: _vm.dsrCalculate,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.d_s_r.in_c5c_amt,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.d_s_r,
                                                            "in_c5c_amt",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "d_s_r.in_c5c_amt",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("tfoot", [
                                        _c(
                                          "tr",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "td",
                                              { attrs: { colspan: "2" } },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [_vm._v(" Jumlah: ")]
                                                ),
                                              ]
                                            ),
                                            _c("td", [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [
                                                  _c("vuetify-money", {
                                                    attrs: {
                                                      dense: _vm.moneyDense,
                                                      "hide-details":
                                                        _vm.moneyHideDetails,
                                                      filled: true,
                                                      readonly: true,
                                                      disabled: false,
                                                      outlined: false,
                                                      options: _vm.dsrOpt,
                                                      properties:
                                                        _vm.moneyDSRProperties,
                                                      valueWhenIsEmpty:
                                                        _vm.moneyWhenEmpty,
                                                    },
                                                    on: {
                                                      input: _vm.dsrCalculate,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.d_s_r.in_c_total,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.d_s_r,
                                                          "in_c_total",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "d_s_r.in_c_total",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "pr-3 pl-3 pb-2 pt-10",
          staticStyle: {
            display: "flex",
            "align-items": "flex-end",
            "flex-direction": "column",
          },
        },
        [
          !_vm.isDisabled
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { color: "primary", loading: _vm.isLoadingDSR },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Simpan"),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("ErrorMsgDialog", {
        attrs: {
          title: "Ralat",
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
          proceedButton: "OK",
        },
        model: {
          value: _vm.openErrorMsgDialog,
          callback: function ($$v) {
            _vm.openErrorMsgDialog = $$v
          },
          expression: "openErrorMsgDialog",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          title: "Info",
          content: _vm.msgINFO.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          proceedButton: "OK",
        },
        model: {
          value: _vm.openInfoMsgDialog,
          callback: function ($$v) {
            _vm.openInfoMsgDialog = $$v
          },
          expression: "openInfoMsgDialog",
        },
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }