var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card-text", [
        _c(
          "div",
          [
            _c(
              "ValidationObserver",
              { ref: "heirValidation" },
              [
                _c(
                  "v-form",
                  { staticClass: "font-weight-bold font-size-sm" },
                  [
                    _c(
                      "v-container",
                      { attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Maklumat Waris ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Nama Pewaris",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              label: "Nama Pewaris",
                                              hint: "Nama seperti dalam Kad Pengenalan",
                                              maxLength: "128",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.heir.heir_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_name",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_name",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "No. Kad Pengenalan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.mask_icNum,
                                                expression: "mask_icNum",
                                              },
                                            ],
                                            attrs: {
                                              maxLength: "14",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "No. Kad Pengenalan",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            on: {
                                              change: _vm.computedInfoNRICHeir,
                                            },
                                            model: {
                                              value: _vm.heir.heir_nric,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_nric",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_nric",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Tarikh Lahir",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Tarikh Lahir",
                                              hint: "Tarikh Lahir diperoleh daripada Nombor Kad Pengenalan",
                                              value: _vm.computedHeirBirthDate,
                                              disable: _vm.isDisabled,
                                              "error-messages": errors,
                                              required: "",
                                              readonly: "",
                                              outlined: "",
                                              filled: "",
                                              dense: "",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Jantina", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              label: "Jantina",
                                              items: _vm.genders,
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              outlined: "",
                                              required: "",
                                              filled: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.heir.heir_gender_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_gender_id",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_gender_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Bangsa", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              label: "Bangsa",
                                              items: _vm.races,
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              outlined: "",
                                              required: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.heir.heir_race_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_race_id",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_race_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Agama", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.religions,
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Agama",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.heir.heir_religion_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_religion_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "heir.heir_religion_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Hubungan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.relations,
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Hubungan",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.heir.relationship_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "relationship_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "heir.relationship_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Alamat Rumah ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Unit/Lot/No./Tingkat",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              hint: "Contoh A1-2-3",
                                              "error-messages": errors,
                                              maxLength: "255",
                                              readonly: _vm.isDisabled,
                                              label: "Unit/Lot/No./Tingkat",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.heir.heir_unit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_unit",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_unit",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Jalan/Apartment/Taman",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              hint: "Jalan Bahagia Kampung Sentosa",
                                              "error-messages": errors,
                                              maxLength: "255",
                                              readonly: _vm.isDisabled,
                                              label: "Jalan/Apartment/Taman",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.heir.heir_street,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_street",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_street",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Butiran Tambahan 1",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              hint: "Masukkan butiran alamat sekiranya ada",
                                              maxLength: "255",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label:
                                                "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.heir.heir_line3,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_line3",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_line3",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Butiran Tambahan 2",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              hint: "Masukkan butiran alamat sekiranya ada",
                                              maxLength: "255",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label:
                                                "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.heir.heir_line4,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_line4",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_line4",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Poskod", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.mask_postCode,
                                                expression: "mask_postCode",
                                              },
                                            ],
                                            attrs: {
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              maxLength: "5",
                                              label: "Poskod",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.heir.heir_postcode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_postcode",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_postcode",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Bandar", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.city,
                                              "item-text": "display",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              hint: "Bandar mengikut poskod",
                                              label: "Bandar",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            on: {
                                              change: _vm.onHeirCityChange,
                                            },
                                            model: {
                                              value: _vm.heir.heir_city_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_city_id",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_city_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Negeri", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.states,
                                              "item-text": "name",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Negeri",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.heir.heir_state_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.heir,
                                                  "heir_state_id",
                                                  $$v
                                                )
                                              },
                                              expression: "heir.heir_state_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Telefon ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("vue-tel-input-vuetify", {
                                  attrs: {
                                    maxlength: 13,
                                    readonly: _vm.isDisabled,
                                    placeholder: "Telefon Bimbit",
                                    label: "Telefon Bimbit",
                                    hint: "Contoh: 010-123 4567 / 011-1234 5678",
                                    defaultCountry: "MY",
                                    onlyCountries: ["MY"],
                                    "background-color":
                                      _vm.inputColorHeirPhoneNum,
                                    translations: {
                                      countrySelectorLabel: "",
                                      countrySelectorError: "",
                                      phoneNumberLabel: "Telefon Bimbit",
                                      example: "Contoh :",
                                    },
                                    outlined: "",
                                    dense: "",
                                    required: "",
                                  },
                                  on: { input: _vm.onHeirPhoneNumInput },
                                  model: {
                                    value: _vm.heir.heir_phone_num,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.heir, "heir_phone_num", $$v)
                                    },
                                    expression: "heir.heir_phone_num",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("vue-tel-input-vuetify", {
                                  attrs: {
                                    maxlength: 13,
                                    readonly: _vm.isDisabled,
                                    placeholder: "Telefon Rumah",
                                    label: "Telefon Rumah",
                                    hint: "Contoh: 03-1234 5678 / 04-123 4567",
                                    defaultCountry: "MY",
                                    onlyCountries: ["MY"],
                                    "background-color":
                                      _vm.inputColorHeirHomeNum,
                                    translations: {
                                      countrySelectorLabel: "",
                                      countrySelectorError: "",
                                      phoneNumberLabel: "Telefon Rumah",
                                      example: "Contoh :",
                                    },
                                    outlined: "",
                                    dense: "",
                                    required: "",
                                  },
                                  on: { input: _vm.onHeirHomeNumInput },
                                  model: {
                                    value: _vm.heir.heir_home_num,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.heir, "heir_home_num", $$v)
                                    },
                                    expression: "heir.heir_home_num",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("vue-tel-input-vuetify", {
                                  attrs: {
                                    maxlength: 13,
                                    readonly: _vm.isDisabled,
                                    placeholder: "Telefon Pejabat",
                                    label: "Telefon Pejabat",
                                    hint: "Contoh: 03-1234 5678 / 04-123 4567",
                                    defaultCountry: "MY",
                                    onlyCountries: ["MY"],
                                    "background-color":
                                      _vm.inputColorHeirOfficeNum,
                                    translations: {
                                      countrySelectorLabel: "",
                                      countrySelectorError: "",
                                      phoneNumberLabel: "Telefon Pejabat",
                                      example: "Contoh :",
                                    },
                                    outlined: "",
                                    dense: "",
                                    required: "",
                                  },
                                  on: { input: _vm.onHeirOfficeNumInput },
                                  model: {
                                    value: _vm.heir.heir_office_num,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.heir, "heir_office_num", $$v)
                                    },
                                    expression: "heir.heir_office_num",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "pr-3 pl-3 pb-2 pt-10",
          staticStyle: {
            display: "flex",
            "align-items": "flex-end",
            "flex-direction": "column",
          },
        },
        [
          !_vm.isDisabled
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { color: "primary", loading: _vm.flagLoading },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Simpan"),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbar.color, elevation: "24", top: "" },
          model: {
            value: _vm.snackbar.visible,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "visible", $$v)
            },
            expression: "snackbar.visible",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [_c("v-icon", [_vm._v(_vm._s(_vm.snackbar.icon))])],
                1
              ),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("div", { staticClass: "mx-3" }, [
                  _vm._v(" " + _vm._s(_vm.snackbar.title) + " "),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
                  ]),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.snackbar.visible = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ErrorMsgDialog", {
        attrs: {
          title: "Ralat",
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
          proceedButton: "OK",
        },
        model: {
          value: _vm.showErrorMsgDialog,
          callback: function ($$v) {
            _vm.showErrorMsgDialog = $$v
          },
          expression: "showErrorMsgDialog",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          title: "Info",
          content: _vm.msgINFO.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          proceedButton: "OK",
        },
        model: {
          value: _vm.showInfoMsgDialog,
          callback: function ($$v) {
            _vm.showInfoMsgDialog = $$v
          },
          expression: "showInfoMsgDialog",
        },
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }