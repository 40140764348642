<template
>
  <v-row
    :justify="alignCenter"
  >
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      top
    >
      <v-row
        no-gutters
        :align="alignCenter"
        :justify="alignCenter"
      >
        <v-col
          cols="1"
        >
          <v-icon
          >
            {{ snackbar.icon }}
          </v-icon>
        </v-col>
        <v-col
          cols="10"
        >
          <div
            class="mx-3"
          >
            <!-- <p class="snackbar-title font-weight-bold h3-font-size mb-0"> -->
            {{ snackbar.title }}
            <!-- </p> -->
            <p
              class="snackbar-subtitle mb-0"
            >
              {{ snackbar.text }}
            </p>
          </div>
        </v-col>
        <v-col
          cols="1"
        >
          <v-btn
            icon
            @click="snackbar.visible = false"
          >
            <v-icon
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >
      <ApplicationInfo
        @salesCompleteAction="completeApplication"
        @processingCompleteAction="processing"
      />

      <div
        class="card card-custom"
      >
        <div
          class="card-body p-0"
        >
          <b-card
            no-body
          >
            <b-tabs
              v-model="tIndex"
              pills
              card
            >
              <b-tab
                title-link-class="font-weight-bold"
                title="Maklumat Diri"
              >
                <MemberDetails
                  ref="memberDetails"
                  :propData="application"
                  :propReadOnly="isDisabled"
                ></MemberDetails>
              </b-tab>

              <b-tab
                title-link-class="font-weight-bold"
                title="Maklumat Pekerjaan"
              >
                <EmploymentDetails
                  ref="employmentDetails"
                  :propReadOnly="isDisabled"
                ></EmploymentDetails>
              </b-tab>

              <b-tab
                title-link-class="font-weight-bold"
                title="Maklumat Waris"
              >
                <HeirDetails
                  ref="heirDetails"
                  :propReadOnly="isDisabled"
                ></HeirDetails>
              </b-tab>

              <b-tab
                title-link-class="font-weight-bold"
              >
                <template
                  slot="title"
                >
                  Dokumen Pengesahan
                  <v-badge
                    v-if="this.application == null
                      ? 0
                      : this.application.checklist == 0
                    "
                    class="ml-2 mx-1"
                    color="pink"
                    dot
                  ></v-badge>
                </template>

                <b-card-text>
                  <DocumentForm
                    :propId="currentId"
                    :propForm="application"
                    :propRoles="currentUserRoles"
                    :propDocList="checklistAppFull"
                    :propReadOnly="isDisabled"
                    :propUseForm="!true"
                  ></DocumentForm>
                </b-card-text>
              </b-tab>

              <b-tab
                v-if="currentUserRoles.includes(2) || currentUserRoles.includes(3)"
                title="DSR"
                title-link-class="font-weight-bold"
              >
                <DSRDetails
                  :propReadOnly="isDisabled"
                ></DSRDetails>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>

      <ErrorMsgDialog
        v-model="openErrorMsgDialog"
        title="Ralat"
        :content="msgERR.message"
        :showProceedButton="true"
        :showBackButton="false"
        :delayOnProceed="false"
        :isTypeError="true"
        :modal="true"
        proceedButton="OK"
      ></ErrorMsgDialog>

      <InfoMsgDialog
        v-model="openInfoMsgDialog"
        title="Info"
        :content="msgINFO.message"
        :showProceedButton="true"
        :showBackButton="false"
        :delayOnProceed="false"
        :modal="true"
        proceedButton="OK"
      ></InfoMsgDialog>

      <v-overlay
        :value="overlay"
      >
        <v-progress-circular
          indeterminate size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-col>
  </v-row>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
// import moment from "moment";
import dayjs from "dayjs";
// import { ref } from "vue";
import {
  mapGetters,
} from "vuex";

import {
  SET_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module";

import {
  SHOW_APPLICATION,
  SET_APPLICATION,
  COMPLETE_APPLICATION,
  PROCESS_TO_ILMS,
  UPDATE_APPLICATION_FINANCING,
  UNSET_APPLICATION,
  GET_CHECKLIST_APP_FULL,
} from "@/core/services/store/application.module";

// import {
//   // GET_CHECKLIST_MASTERS,
//   // GET_CHECKLIST_FULL,
//   // SET_CHECKLIST_MASTERS,
// } from "@/core/services/store/form.module";
import // GET_EMPLOYER_TYPE_LIST,
  // SET_EMPLOYER_TYPE_LIST,
  "@/core/services/store/lookup.module";

import {
  GET_USER,
} from "@/core/services/store/user.module";

import {
  CREATE_DSR,
} from "@/core/services/store/dsr.module";

import {
  blankUser_old,
  blankMember,
  blankEmployment,
  blankHeir,
  blankDSR,
  getSecuredFile,
  errorMessages,
  calcDSR,
} from "@/core/services/helper.service";

import {
  DELETE_MEDIA,
} from "@/core/services/store/mediaupload.module";

import ApplicationInfo   from "@/view/pages/widgets/ApplicationInfo.vue";
import DocumentForm      from "@/view/pages/form/DocumentForm.vue";
import MemberDetails     from "@/view/pages/form/edit/MemberDetails.vue";
import EmploymentDetails from "@/view/pages/form/edit/EmploymentDetails.vue";
import HeirDetails       from "@/view/pages/form/edit/HeirDetails.vue";
import DSRDetails        from "@/view/pages/form/edit/DSRDetails.vue";

import {
  required,
  email,
  max,
  numeric,
} from "vee-validate/dist/rules";

import {
  extend,
  setInteractionMode,
} from "vee-validate";

import {
  UPDATE_HEIR,
  UPDATE_MEMBER,
  UPDATE_EMPLOYMENT,
} from "@/core/services/store/application.module";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("email", {
  ...email,
  message: "E-mel mestilah sah",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});

export default {
  name: "EditForm",

  components: {
    ApplicationInfo,
    DocumentForm,
    MemberDetails,
    EmploymentDetails,
    HeirDetails,
    DSRDetails,
  },

  data() {
    return {
      alignCenter: "center",
      errors: "",
      // Widget toggle state
      // showConfirmationDialog: false,
      overlay: true,
      // Error Popup
      openErrorMsgDialog: false,
      snackbar: {
        visible: false,
        icon: "",
        title: "",
        text: "",
        color: "",
      },
      msgERR: {
        message: "",
        nric: "",
        run: 0,
      },
      // Info Popup
      openInfoMsgDialog: false,
      msgINFO: {
        message: "",
        nric: "",
        run: 0,
      },
      // Calculator
      calculator: {
        total: 0,
      },
      // userId: null,
      user: blankUser_old(),
      selectedBank: {},
      city: [],
      races: [],
      banks: [],
      genders: [],
      salute: [],
      states: [],
      religions: [],
      relations: [],
      employerTypes: [],
      maritalStatus: [],
      houseOwnership: [],
      educationLevel: [],
      occupationTypes: [],
      cityHeirScoped: [],
      cityHomeScoped: [],
      cityOfficeScoped: [],
      cityMailingScoped: [],
      // VuetifyMoney
      moneyDense: true,
      moneyProperties: {
        error: true,
        "hide-details": true,
      },

      moneyPropertiesRequired: {
        error: true,
        "hide-details": true,
        rules: "required",
      },

      moneyDSRProperties: {
        error: false,
        'hide-details': true,
      },

      moneyDSRCalcProperties: {
        error: false,
        'hide-details': true,
        filled: true,
      },

      moneyReadOnly: false,
      moneyDisabled: false,
      moneyOutlined: true,
      moneyClearable: true,
      moneyNumEmpty: "",
      moneyWhenEmpty: "0",
      moneyWhenEmpty2Dec: "0.00",
      loanAmountLabel: "Jumlah Pembiayaan Dipohon",
      loanTenureLabel: "Tempoh (Bulan)",
      loanRateLabel: "Kadar (%)",
      loanInstLabel: "Ansuran Bulanan",
      loanAmountPlaceholder: "RM 1,000 ~ RM 100,000",
      loanAmountHint: "Minimum RM 1,000 & Maksima RM 100,000",
      salaryLabel: "Pendapatan Sebulan",
      salaryPlaceholder: "Gaji Kasar",
      salaryHint: "Masukkan Nilai Gaji Kasar Sebelum Penolakan",
      salaryOpt: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 2,
        min: 500,
        max: 1000000,
      },
      loanOpt: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 0,
        min: 1000,
        max: 1000000,
      },

      loanOpt2: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 2,
        min: 1000,
        max: 200000,
      },

      percent: {
        locale: "ms-MY",
        prefix: "",
        suffix: "%",
        length: 5,
        precision: 2,
        min: 0,
        max: 100,
      },

      tenure: {
        locale: "ms-MY",
        prefix: "",
        suffix: "BULAN",
        length: 2,
        precision: 0,
        min: 12,
        max: 240,
      },

      dsrOpt: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 2,
        min: 0,
        max: 1000000,
      },
      // Start mask
      mask_icNum: "######-##-####",
      mask_postCode: "#####",
      mask_phoneMobile: "###-########",
      mask_phoneLand: "##-########",
      mask_bankAcc: "####################",
      mask_yearServing: "##",
      // End mask
      member: blankMember(),
      employment: blankEmployment(),
      heir: blankHeir(),
      d_s_r: blankDSR(),
      // Start date menu
      userBirthDateMenu: false,
      heirBirthDateMenu: false,
      employmentDateOfServiceDateMenu: false,
      // End date menu
      showMailingAddress: true,
      // showUploadManager: false,
      isLoading: false,
      isLoadingProcessing: false,
      isLoadingHeir: false,
      isLoadingMember: false,
      isLoadingEmployment: false,
      isLoadingDSR: false,
      uploadLabel: "",
      useAddressAsMailingAddress: false,
      // Checklist
      // checklistData: [],
      // checklistDownloadData: [],
      checklistFulfilmentCount: 0,
      // checklistFulfilled: false,
      // Delete Media
      tIndex: 0,
      mediaIndex: null,
      mediaChecklist: null,
      openCalculatorDialog: false,
      memberChecked: false,
      testTitle: "",
      testMessage: "",
      testType: "",
      showSnackbar: false,
    };
  },

  computed: {
    ...mapGetters([
      "application",
      "checklistAppFull",
      "currentUserRoles",
    ]),

    isInArray: function () {
      return this.currentUserRoles.includes(value);
    },

    tabIndex: {
      get() {
        return this.$route.params.tabIndex ? this.$route.params.tabIndex : 0;
      },
      set(newValue) {
        return newValue;
      },
    },

    currentId() {
      return parseInt(this.$route.params.id) ?? 0;
    },

    checklistFulfilled() {
      return this.checklistFulfilmentCount == 0;
    },

    isSalesCompleted() {
      // Only disabled when it is completed and not being viewed as Processing user roles
      // return this?.application?.is_completed == true && !this.currentUserRoles.includes(2) && this.checklistFulfilled ? true : false;
      return !!(this?.application?.is_completed == true && !!!this.currentUserRoles.includes(2));
    },

    isSalesCompletable() {
      return (!this.isSalesCompleted && this.currentUserRoles.includes(3) && this.checklistFulfilled);
    },

    isExportable() {
      return false;
    },

    getSalary() {
      return parseFloat(this.employment.salary);
    },

    getEmployerTypeID() {
      return parseInt(this.employment.employer_type_id);
    },

    getEmployerID() {
      return parseInt(this.employment.employerId);
    },

    isMoneyClearable() {
      return !!(this.isDisabled ? false : true);
    },

    isMoneyDisabled() {
      return !!this.isDisabled;
    },

    isMoneyReadOnly() {
      return !!this.isDisabled;
    },

    getBank() {
      this.banks.find((_bank) => {
        if (_bank.id == this.member.banks_id) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selectedBank = _bank;
          return true;
        }
      }, this);
      return this.selectedBank.id;
    },

    isDisabled() {
      return this.currentUserRoles.includes(3) ? true : false;
    },

    checklistFulfillment() {
      return this.checklistFulfilmentCount == 0;
    },
  },

  async created() {
    //nfh-console.log(' IN:CREATED');
    await this.getFormData(this);
    this.city              = JSON.parse(localStorage.getItem("cty"));
    this.races             = JSON.parse(localStorage.getItem("rce"));
    this.banks             = JSON.parse(localStorage.getItem("bnk"));
    this.genders           = JSON.parse(localStorage.getItem("gdr"));
    this.salute            = JSON.parse(localStorage.getItem("sal"));
    this.states            = JSON.parse(localStorage.getItem("stt"));
    this.religions         = JSON.parse(localStorage.getItem("rgn"));
    this.relations         = JSON.parse(localStorage.getItem("rel"));
    this.employerTypes     = JSON.parse(localStorage.getItem("emp"));
    this.maritalStatus     = JSON.parse(localStorage.getItem("mry"));
    this.houseOwnership    = JSON.parse(localStorage.getItem("own"));
    this.educationLevel    = JSON.parse(localStorage.getItem("edu"));
    this.occupationTypes   = JSON.parse(localStorage.getItem("occ"));
    this.cityHeirScoped    = this.city.slice();
    this.cityHomeScoped    = this.city.slice();
    this.cityOfficeScoped  = this.city.slice();
    this.cityMailingScoped = this.city.slice();

    await this.$store.dispatch(GET_USER);

    if (!this.application) {
      //nfh-console.log('this.application is blank, populate application first.');

      await this.$store.dispatch(SHOW_APPLICATION, this.$route.params.id)
        .then(async (resp) => {
          if (resp.success) {
            this.$store.commit(SET_APPLICATION, resp.data);

            this.heir       = resp.data.heir ?? blankHeir();
            this.d_s_r      = resp.data.d_s_r_worksheet ?? blankDSR();
            this.member     = resp.data.member ?? blankMember();
            this.employment = resp.data.employment ?? blankEmployment();
          }
        });
    }

    // if (!this.checklistMasters || this.checklistMasters.length < 1) {
    //   console.log('checklistMasters is blank, populate checklistMasters first.');
    //   await this.$store.dispatch(GET_CHECKLIST_MASTERS);
    // }

    await this.$store.dispatch(GET_CHECKLIST_APP_FULL, this.$route.params.id);
    //nfh-console.log('OUT:CREATED');
  },

  async mounted() {
    //nfh-console.log(' IN:MOUNTED');

    this.showSnackbar = true;

    try {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sunting Permohonan" }]);
      this.tabIndex = this.$route.params.tabIndex;
      this.tIndex   = this.tabIndex;
    }
    catch (err) {
      this.promptError(err);
    }
    finally {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    }
    //nfh-console.log('OUT:MOUNTED');
  },

  methods: {
    showSnackbarSuccess(message = "", title = "") {
      if (message != "") {
        this.snackbar.icon    = "mdi-check-circle";
        this.snackbar.color   = "success";
        this.snackbar.title   = title;
        this.snackbar.text    = message;
        this.snackbar.visible = true;
      }
    },

    showSnackbarFailure(message = "", title = "") {
      if (message != "") {
        this.snackbar.icon    = "mdi-close-circle";
        this.snackbar.color   = "red";
        this.snackbar.title   = title;
        this.snackbar.text    = message;
        this.snackbar.visible = true;
      }
    },

    showSnackbarReset() {
      this.snackbar.icon    = "";
      this.snackbar.color   = "";
      this.snackbar.title   = "";
      this.snackbar.text    = "";
      this.snackbar.visible = false;
    },

    async getFormData(thisArg) {
      //nfh-console.log(' IN:GETFORMDATA');
      // await this.$store
      //   .dispatch(SHOW_APPLICATION, this.$route.params.id)
      //   .then(() => {
      //     thisArg.member = thisArg.application.member;
      //     thisArg.employment = thisArg.application.employment;
      //     thisArg.heir = thisArg.application.heir;
      //     thisArg.d_s_r =
      //       thisArg.application.d_s_r_worksheet == null
      //         ? this.dsr
      //         : thisArg.application.d_s_r_worksheet;
      //   });

      await this.$store.dispatch(SHOW_APPLICATION, this.$route.params.id)
        .then(async (response) => {
          if (response?.success) {
            // response.data.data.employment.salary = Number.parseFloat(response.data.data.employment.salary);
            await this.$store.commit(SET_APPLICATION, response.data);

            // this.heir = this.application.heir ?? blankHeir();
            // this.d_s_r = this.application.d_s_r_worksheet ?? blankDSR();
            // this.member = this.application.member ?? blankMember();
            // this.employment = this.application.employment ?? blankEmployment();

            this.heir = response.data.heir ?? blankHeir();
            //nfh-console.log('this.heir assigned');
            this.d_s_r = response.data.d_s_r_worksheet ?? blankDSR();
            //nfh-console.log('this.d_s_r assigned');
            this.member = response.data.member ?? blankMember();
            //nfh-console.log('this.member assigned');
            this.employment = response.data.employment ?? blankEmployment();
            //nfh-console.log('this.employment assigned');

            // await this.$store.dispatch(GET_CHECKLIST_MASTERS);
            await this.$store.dispatch(GET_CHECKLIST_APP_FULL, this.$route.params.id);
            // .then((resp) => {
            //   // xxconsole.log(resp);
            //   this.$store.commit(SET_CHECKLIST_MASTERS, resp.data.data);
            // });
            // debugger;
            // thisArg.mergeChecklist();
            // thisArg.updateChecklistData();
          }
          else {
            let _err = (response?.message) ? response?.message : "Butiran permohonan tidak dapat dicapai buat masa ini. Harap maaf.";
            this.promptError(_err);
            this.$router.replace({
              name: "Dashboard",
            });
          }
        });
      //nfh-console.log('OUT:GETFORMDATA');
    },

    async processing() {
      //nfh-console.log(' IN:PROCESSING');
      this.isLoadingProcessing = true;
      setTimeout(async () => {
        await this.$store.dispatch(PROCESS_TO_ILMS, { application_id: this.$route.params.id })
          .then((response) => {
            if (response.data.success == true) {
              this.snackbar.icon       = "mdi-check-circle";
              this.snackbar.color      = "success";
              this.snackbar.title      = "Berjaya.";
              this.snackbar.text       = "Permohonan telah dihantar ke ILMS.";
              this.snackbar.visible    = true;
              this.isLoadingProcessing = false;
            }
            else if (response.data.success == false) {
              this.snackbar.icon       = "mdi-message-alert";
              this.snackbar.color      = "blue-grey";
              this.snackbar.title      = "AMBIL PERHATIAN.";
              this.snackbar.text       = response.data.message;
              this.snackbar.visible    = true;
              this.isLoadingProcessing = false;
            }
            else {
              this.snackbar.icon       = "mdi-close-circle";
              this.snackbar.color      = "red";
              this.snackbar.title      = "Tidak Berjaya.";
              this.snackbar.text       = response.data.message || response.data?.data?.application?.errMsg;
              this.snackbar.visible    = true;
              this.isLoadingProcessing = false;
            }
          })
          .catch((err) => {
            this.isLoadingProcessing = false;
            alert(err);
          })
          .finally(() => {
            this.isLoadingProcessing = false;
          });
      });
      //nfh-console.log('OUT:PROCESSING');
    },

    async completeApplication() {
      //nfh-console.log(' IN:COMPLETEAPPLICATION');
      this.$refs.memberDetails.onValidate()
        .then((mSuccess) => {
          if (mSuccess) {
            this.$refs.employmentDetails.onValidate()
              .then((eSuccess) => {
                if (eSuccess) {
                  this.$refs.heirDetails.onValidate()
                    .then((hSuccess) => {
                      if (hSuccess) {
                        // if (this.checklistFulfilled) {
                          this.isLoading = true;
                          this.showSnackbarSuccess("yeay");
                          // setTimeout(async () => {
                          //   await this.$store
                          //     .dispatch(COMPLETE_APPLICATION, this.$route.params.id)
                          //     .then((response) => {
                          //       if (response.data.success == true) {
                          //         this.isLoading = false;
                          //         this.$router.push({
                          //           name: "Dashboard",
                          //           query: {
                          //             params: "complete_application",
                          //             title: "Berjaya",
                          //             text: response.data.message,
                          //           },
                          //         });
                          //       }
                          //     })
                          //     .catch((err) => {
                          //       this.isLoading = false;
                          //     });
                          // }, 2000);
                        // }
                        // else {
                        //   this.tIndex = 3;
                        //   this.showSnackbarFailure("Sila pastikan semua dokumen wajib telah di muat-naik.", "Dokumen Tidak Lengkap");
                        //   this.isLoadingProcessing = false;
                        //   this.goToError(this.$refs.checklistValidation);
                        // }
                      }
                      else {
                        this.tIndex = 2;
                        this.showSnackbarFailure("Sila lengkapkan ruangan data wajib.", "Data Tidak Lengkap");
                        // this.isLoadingProcessing = false;
                      }
                    });
                }
                else {
                  this.tIndex = 1;
                  this.showSnackbarFailure("Sila lengkapkan ruangan data wajib.", "Data Tidak Lengkap");
                  // this.isLoadingProcessing = false;
                }
              });
          }
          else {
            this.tIndex = 0;
            this.showSnackbarFailure("Sila lengkapkan ruangan bertanda merah.", "Data Tidak Lengkap");
            // this.isLoadingProcessing = false;
          }
        });
      //nfh-console.log('OUT:COMPLETEAPPLICATION');
    },

    async checkAppCompleted() {
      this.$refs.memberDetails.onValidate()
        .then((mSuccess) => {
          if (mSuccess) {
            this.$refs.employmentDetails.onValidate()
              .then((eSuccess) => {
                if (eSuccess) {
                  this.$refs.heirDetails.onValidate()
                    .then((hSuccess) => {
                      if (hSuccess) {
                        this.isLoading = true;
                        // setTimeout(async () => {
                        //   await this.$store
                        //     .dispatch(COMPLETE_APPLICATION, this.$route.params.id)
                        //     .then((response) => {
                        //       if (response.data.success == true) {
                        //         this.isLoading = false;
                        //         this.$router.push({
                        //           name: "Dashboard",
                        //           query: {
                        //             params: "complete_application",
                        //             title: "Berjaya",
                        //             text: response.data.message,
                        //           },
                        //         });
                        //       }
                        //     })
                        //     .catch((err) => {
                        //       this.isLoading = false;
                        //     });
                        // }, 2000);
                      }
                      else {
                        this.tIndex = 2;
                        this.showSnackbarFailure("Sila lengkapkan ruangan bertanda merah.", "Data Tidak Lengkap");
                      }
                    });
                }
                else {
                  this.tIndex = 1;
                  this.showSnackbarFailure("Sila lengkapkan ruangan bertanda merah.", "Data Tidak Lengkap");
                }
              });
          }
          else {
            this.tIndex = 0;
            this.showSnackbarFailure("Sila lengkapkan ruangan bertanda merah.", "Data Tidak Lengkap");
          }
        });
    },

    // goToError(_ref) {
    //   setTimeout(() => {
    //     const errors = Object.entries(_ref.errors)
    //       .map(([key, value]) => ({ key, value }))
    //       .filter((error) => error["value"].length);

    //     _ref.refs[errors[0]["key"]]?.$el.scrollIntoView({
    //       behavior: "smooth",
    //       block: "center",
    //     });
    //   }, 200);
    // },

    // async calculate(value) {
    //   // xxconsole.log(value);
    //   await this.$store
    //     .dispatch(CALCULATE, {
    //       salary: +value.salary,
    //       deductions: value.deductions,
    //       employer: value.employerId,
    //     })
    //     .then((response) => {
    //       this.$store.commit(SET_CALC_RESULT, response.data);
    //     });
    // },

    // saveCalcResult(total, rate, tenure, inst, deds) {
    //   this.calculator.total = total;
    //   this.member.financing_req_amount = total;
    //   this.member.financing_req_tenure = tenure;
    //   this.member.financing_req_rate = rate;
    //   this.member.financing_req_inst = inst;
    //   this.member.financing_req_deds = deds;
    //   this.application.request_tenure = tenure;
    //   this.application.request_amount = total;
    //   this.application.request_rate = rate;
    //   this.application.request_inst = inst;
    //   this.application.request_deductions = deds;
    //   this.openCalculatorDialog = false;
    // },

    // onBankAccChange(value) {
    //   // xxconsole.log("onBankAccChange");
    //   this.mask_bankAcc = "#".repeat(value.max);
    //   this.member.bank_name = value.name;
    //   this.member.banks_id = value.id;
    //   // this.member.bank_acc = null;
    // },
    // onBankAccInput() {
    //   // xxconsole.log("onBankAccInput");
    //   this.mask_bankAcc = "#".repeat(this.selectedBank.max);
    //   this.member.bank_name = this.selectedBank.name;
    //   this.member.banks_id = this.selectedBank.id;
    // },
    // useMainAddress() {
    //   this.showMailingAddress = !this.showMailingAddress;
    //   this.member.mailing_unit = this.member.home_unit;
    //   this.member.mailing_street = this.member.home_street;
    //   this.member.mailing_postcode = this.member.home_postcode;
    //   this.member.mailing_city_id = this.member.home_city_id;
    //   this.member.mailing_state_id = this.member.home_state_id;
    // },
    // onHomeCityChange(value) {
    //   if (
    //     this.city.find((ct) => {
    //       if (ct.id == value) {
    //         this.member.home_state_id = ct.state_id;
    //       }
    //     })
    //   );
    // },
    // onMailingCityChange(value) {
    //   if (
    //     this.city.find((ct) => {
    //       if (ct.id == value) {
    //         this.member.mailing_state_id = ct.state_id;
    //       }
    //     })
    //   );
    // },
    // onHeirCityChange(value) {
    //   if (
    //     this.city.find((ct) => {
    //       if (ct.id == value) {
    //         this.heir.heir_state_id = ct.state_id;
    //       }
    //     })
    //   );
    // },
    // onOfficeCityChange(value) {
    //   if (
    //     this.city.find((ct) => {
    //       if (ct.id == value) {
    //         this.employment.office_state_id = ct.state_id;
    //       }
    //     })
    //   );
    // },
    // onHomeStateChange(value) {
    //   this.cityHomeScoped = value
    //     ? this.city.slice().filter((ct) => ct.state_id === value)
    //     : this.city.slice();
    // },
    // onMailingStateChange(value) {
    //   this.cityMailingScoped = value
    //     ? this.city.slice().filter((ct) => ct.state_id === value)
    //     : this.city.slice();
    // },
    // onHeirStateChange(value) {
    //   this.cityHeirScoped = value
    //     ? this.city.slice().filter((ct) => ct.state_id === value)
    //     : this.city.slice();
    // },
    // onOfficeStateChange(value) {
    //   this.cityOfficeScoped = value
    //     ? this.city.slice().filter((ct) => ct.state_id === value)
    //     : this.city.slice();
    // },
    // onCommenceDateChange() {
    //   this.employment.year_of_service = dayjs().diff(
    //     dayjs(this.employment.commencement_date),
    //     "years"
    //   );
    // },
    // dsrCalculate(e) {
    //   this.d_s_r = calcDSR(this.d_s_r);
    // },

    // openCalculator() {
    //   this.openCalculatorDialog = !this.isDisabled;
    // },

    // saveMemberForm() {
    //   if (!this.isDisabled) {
    //     this.$refs.memberEditFormValidation.validate().then((success) => {
    //       if (success) {
    //         this.isLoadingMember = true;
    //         // setTimeout(() => {
    //         //   this.$store.dispatch(UPDATE_MEMBER, this.member);
    //         //   this.isLoadingMember = false;
    //         //   this.snackbar.visible = true;
    //         //   (this.snackbar.icon = "mdi-check-circle"),
    //         //     (this.snackbar.title = "Berjaya.");
    //         //   this.snackbar.text = "Maklumat diri berjaya disimpan.";
    //         //   this.snackbar.color = "success";
    //         // }, 2000);
    //         setTimeout(() => {
    //           this.$store.dispatch(UPDATE_MEMBER, this.member).then((response) => {
    //             if (response.success) {
    //               this.snackbar.icon = "mdi-check-circle";
    //               this.snackbar.color = "success";
    //               this.snackbar.title = "Berjaya.";
    //               this.snackbar.text = "Maklumat Diri telah disimpan.";

    //               this.$store.dispatch(UPDATE_APPLICATION_FINANCING, this.application).then((resp) => {
    //                 if (resp.data.success) {
    //                   // xxconsole.log('UPDATE_APPLICATION_FINANCING: success', resp);
    //                 }
    //                 else {
    //                   // xxconsole.log('UPDATE_APPLICATION_FINANCING: failed', resp);
    //                 }
    //               });
    //             }
    //             else {
    //               this.snackbar.icon = "mdi-close-circle";
    //               this.snackbar.color = "red";
    //               this.snackbar.title = "Tidak Berjaya.";
    //               this.snackbar.text = response.message;
    //             }
    //             this.snackbar.visible = true;
    //             this.isLoadingMember = false;
    //           })
    //             .catch((err) => {
    //               this.promptError(err);
    //             })
    //             .finally(() => {
    //               this.isLoadingMember = false;
    //             });
    //         }, 1500);
    //       }
    //       else {
    //         this.tIndex = 0;
    //         this.snackbar.icon = "mdi-close-circle";
    //         this.snackbar.color = "red";
    //         this.snackbar.title = "Data Tidak Lengkap";
    //         this.snackbar.text = "Sila lengkapkan ruangan data wajib.";
    //         this.snackbar.visible = true;
    //         this.isLoadingProcessing = false;
    //         this.goToError(this.$refs.memberEditFormValidation);
    //       }
    //     });
    //   }
    // },

    // saveEmploymentForm() {
    //   if (!this.isDisabled) {
    //     this.$refs.employmentEditFormValidation.validate().then((success) => {
    //       if (success) {
    //         this.isLoadingEmployment = true;
    //         // setTimeout(() => {
    //         //   this.$store.dispatch(UPDATE_EMPLOYMENT, this.employment);
    //         //   this.isLoadingEmployment = false;
    //         //   this.snackbar.visible = true;
    //         //   (this.snackbar.icon = "mdi-check-circle"),
    //         //     (this.snackbar.title = "Berjaya.");
    //         //   this.snackbar.text = "Maklumat pekerjaan berjaya disimpan.";
    //         //   this.snackbar.color = "success";
    //         // }, 2000);
    //         setTimeout(() => {
    //           this.$store.dispatch(UPDATE_EMPLOYMENT, this.employment).then((response) => {
    //             if (response.success) {
    //               // debugger;
    //               // response.data.salary = response.data.salary * 1;
    //               // this.employment = response.data;

    //               this.snackbar.icon = "mdi-check-circle";
    //               this.snackbar.color = "success";
    //               this.snackbar.title = "Berjaya.";
    //               this.snackbar.text = "Maklumat Pekerjaan telah disimpan.";
    //             }
    //             else {
    //               this.snackbar.icon = "mdi-close-circle";
    //               this.snackbar.color = "red";
    //               this.snackbar.title = "Tidak Berjaya.";
    //               this.snackbar.text = response.message;
    //             }
    //             this.snackbar.visible = true;
    //             this.isLoadingEmployment = false;
    //           })
    //             .catch((err) => {
    //               this.promptError(err);
    //             })
    //             .finally(() => {
    //               this.isLoadingEmployment = false;
    //             });
    //         }, 1500);
    //       }
    //       else {
    //         this.tIndex = 1;
    //         this.snackbar.icon = "mdi-close-circle";
    //         this.snackbar.color = "red";
    //         this.snackbar.title = "Data Tidak Lengkap";
    //         this.snackbar.text = "Sila lengkapkan ruangan data wajib.";
    //         this.snackbar.visible = true;
    //         this.isLoadingProcessing = false;
    //         this.goToError(this.$refs.employmentEditFormValidation);
    //       }
    //     });
    //   }
    // },

    // saveHeirForm() {
    //   if (!this.isDisabled) {
    //     this.$refs.heirEditFormValidation.validate().then((success) => {
    //       if (success) {
    //         this.isLoadingHeir = true;
    //         // setTimeout(() => {
    //         //   this.$store.dispatch(UPDATE_HEIR, this.heir);
    //         //   this.isLoadingHeir = false;
    //         //   this.snackbar.visible = true;
    //         //   (this.snackbar.icon = "mdi-check-circle"),
    //         //     (this.snackbar.title = "Berjaya.");
    //         //   this.snackbar.text = "Maklumat waris berjaya disimpan.";
    //         //   this.snackbar.color = "success";
    //         // }, 1500);
    //         setTimeout(() => {
    //           this.$store.dispatch(UPDATE_HEIR, this.heir).then((response) => {
    //             if (response.success) {
    //               this.snackbar.icon = "mdi-check-circle";
    //               this.snackbar.color = "success";
    //               this.snackbar.title = "Berjaya.";
    //               this.snackbar.text = "Maklumat Waris telah disimpan.";
    //             }
    //             else {
    //               this.snackbar.icon = "mdi-close-circle";
    //               this.snackbar.color = "red";
    //               this.snackbar.title = "Tidak Berjaya.";
    //               this.snackbar.text = response.message;
    //             }
    //             this.snackbar.visible = true;
    //             this.isLoadingHeir = false;
    //           })
    //             .catch((err) => {
    //               this.promptError(err);
    //             })
    //             .finally(() => {
    //               this.isLoadingHeir = false;
    //             });
    //         }, 1500);
    //       }
    //       else {
    //         this.tIndex = 2;
    //         this.snackbar.icon = "mdi-close-circle";
    //         this.snackbar.color = "red";
    //         this.snackbar.title = "Data Tidak Lengkap";
    //         this.snackbar.text = "Sila lengkapkan ruangan data wajib.";
    //         this.snackbar.visible = true;
    //         this.isLoadingProcessing = false;
    //         this.goToError(this.$refs.heirEditFormValidation);
    //       }
    //     });
    //   }
    // },

    // saveDSRForm() {
    //   if (!this.isDisabled) {
    //     this.$refs.dsrFormValidation.validate().then((success) => {
    //       if (success) {
    //         this.isLoadingDSR = true;
    //         setTimeout(() => {
    //           this.$store
    //             .dispatch(CREATE_DSR, Object.assign({ application_id: this.$route.params.id }, this.d_s_r))
    //             .then((response) => {
    //               if (response.data.success == true) {
    //                 this.d_s_r = response.data.data;
    //                 this.snackbar.icon = "mdi-check-circle";
    //                 this.snackbar.color = "success";
    //                 this.snackbar.title = "Berjaya.";
    //                 this.snackbar.text = "Maklumat DSR telah disimpan.";
    //                 this.snackbar.visible = true;
    //                 this.isLoadingDSR = false;
    //               }
    //               else {
    //                 this.d_s_r = response.data.data;
    //                 this.snackbar.icon = "mdi-close-circle";
    //                 this.snackbar.color = "red";
    //                 this.snackbar.title = "Tidak Berjaya.";
    //                 this.snackbar.text = "Maklumat DSR tidak berjaya disimpan.";
    //                 this.snackbar.visible = true;
    //                 this.isLoadingDSR = false;
    //               }
    //             })
    //             .catch((err) => {
    //               this.isLoadingDSR = false;
    //             })
    //             .finally(() => {
    //               this.isLoadingDSR = false;
    //             });
    //         });
    //       }
    //       else {
    //         this.tIndex = 4;
    //         this.snackbar.icon = "mdi-close-circle";
    //         this.snackbar.color = "red";
    //         this.snackbar.title = "Data Tidak Lengkap";
    //         this.snackbar.text = "Sila lengkapkan ruangan data wajib.";
    //         this.snackbar.visible = true;
    //         this.isLoadingProcessing = false;
    //         this.goToError(this.$refs.dsrFormValidation);
    //       }
    //     });
    //   }
    // },

    // showAlertDeleteMedia(_index, _checklist) {
    //   this.mediaIndex = _index;
    //   this.mediaChecklist = _checklist;
    //   this.showConfirmationDialog = true;
    // },
    // onUploadMedia(value) {
    //   this.uploadLabel = value;
    //   this.showUploadManager = true;
    // },
    // async deleteMedia() {
    //   await this.$store
    //     .dispatch(DELETE_MEDIA, {
    //       id: this.mediaChecklist.checklist_id,
    //     })
    //     .then(async (resp) => {
    //       if (resp.data.success == true) {
    //         URL.revokeObjectURL(this.checklistData[this.mediaIndex].media_url);
    //         await this.refreshChecklist(this.mediaIndex);
    //       }
    //     });
    // },
    // async refreshChecklist(index) {
    //   // xxconsole.log('refreshChecklist');
    //   this.$store.dispatch(SHOW_APPLICATION, this.$route.params.id).then((resp) => {
    //     // xxconsole.log('this.checklistData', this.checklistData);
    //     // xxconsole.log('this.checklistDownloadData', this.checklistDownloadData);
    //
    //     if (index < this.checklistData?.length) {
    //       this.checklistData[index].checklist_id = "";
    //       this.checklistData[index].media_name = "";
    //       this.checklistData[index].media_url = "";
    //       // this.checklistData[index].mandatory = false;
    //       this.checklistData[index].fulfilled = false;
    //
    //       if (this.checklistData[index].mandatory) {
    //         this.checklistFulfilmentCount++;
    //         // xxconsole.log('refreshChecklist:this.checklistFulfilmentCount', this.checklistFulfilmentCount);
    //       }
    //     }
    //
    //     if (index < this.checklistDownloadData?.length) {
    //       this.checklistDownloadData[index].checklist_id = "";
    //       this.checklistDownloadData[index].media_name = "";
    //       this.checklistDownloadData[index].media_url = "";
    //     }
    //   });
    //   this.showUploadManager = false;
    //   // this.showConfirmationDialog = false;
    //   // xxconsole.log('refreshChecklist:this.checklistFulfilmentCount', this.checklistFulfilmentCount);
    //   // this.checklistFulfilled = this.checklistFulfilmentCount == 0;
    // },
    promptError(message) {
      this.msgERR.message = errorMessages(message);
      this.openErrorMsgDialog = true;
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },

  },

  beforeDestroy() {
    //nfh-console.log(" IN:BEFOREDESTROY");
    this.$store.commit(UNSET_APPLICATION);
    //nfh-console.log("OUT:BEFOREDESTROY");
  }
};
</script>

<style>
.ucase-text input {
  text-transform: uppercase;
}

.v-text-field--outlined {
  font-size: 10pt;
}

.v-text-field label {
  font-size: 10pt;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 10pt;
}
</style>
