var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card-text", [
        _c(
          "div",
          [
            _c(
              "ValidationObserver",
              { ref: "memberValidation" },
              [
                _c(
                  "v-form",
                  { staticClass: "font-weight-bold font-size-sm" },
                  [
                    _c(
                      "v-container",
                      { attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { justify: "space-between" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Maklumat Diri ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Nama Pemohon",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              label:
                                                "Nama Pemohon Seperti dalam MyKad / Kad Pengenalan",
                                              hint: "Nama Pemohon Seperti dalam MyKad / Kad Pengenalan",
                                              maxLength: "128",
                                              readonly: _vm.isDisabled,
                                              "error-messages": errors,
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return $event.target.select()
                                              },
                                            },
                                            model: {
                                              value: _vm.member.full_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "full_name",
                                                  $$v
                                                )
                                              },
                                              expression: "member.full_name",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "3" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Gelaran", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Gelaran",
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              items: _vm.salute,
                                              readonly: _vm.isDisabled,
                                              "error-messages": errors,
                                              outlined: "",
                                              required: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.member.salute_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "salute_id",
                                                  $$v
                                                )
                                              },
                                              expression: "member.salute_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Tarikh Lahir",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              value:
                                                _vm.computedMemberBirthDate,
                                              label: "Tarikh Lahir",
                                              hint: "Tarikh Lahir diperoleh daripada Nombor Kad Pengenalan",
                                              disable: _vm.isDisabled,
                                              "error-messages": errors,
                                              readonly: "",
                                              outlined: "",
                                              filled: "",
                                              dense: "",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "5" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Jantina", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.genders,
                                              readonly: _vm.isDisabled,
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              label: "Jantina",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                              filled: "",
                                            },
                                            model: {
                                              value: _vm.member.gender_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "gender_id",
                                                  $$v
                                                )
                                              },
                                              expression: "member.gender_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "3" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Bangsa", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.races,
                                              readonly: _vm.isDisabled,
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              label: "Bangsa",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.member.race_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "race_id",
                                                  $$v
                                                )
                                              },
                                              expression: "member.race_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "3" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Agama", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.religions,
                                              readonly: _vm.isDisabled,
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              label: "Agama",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.member.religion_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "religion_id",
                                                  $$v
                                                )
                                              },
                                              expression: "member.religion_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "3" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Status Perkahwinan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.maritalStatus,
                                              readonly: _vm.isDisabled,
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              label: "Status Perkahwinan",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.member.marital_status_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "marital_status_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.marital_status_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "3" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Bilangan Anak" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Bilangan Anak (jika ada)",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.member.children,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "children",
                                                  $$v
                                                )
                                              },
                                              expression: "member.children",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Tahap Pendidikan Tertinggi",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.educationLevel,
                                              readonly: _vm.isDisabled,
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              label:
                                                "Tahap Pendidikan Tertinggi",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.member.education_level_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "education_level_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.education_level_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Nama Ibu",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              label: "Nama Ibu",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.member.mother_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "mother_name",
                                                  $$v
                                                )
                                              },
                                              expression: "member.mother_name",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Maklumat Bank ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Bank", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              value: _vm.getBank,
                                              "v-model": _vm.member.banks_id,
                                              items: _vm.banks,
                                              readonly: _vm.isDisabled,
                                              "item-text": "name",
                                              "item-value": "id",
                                              "item-color": "primary",
                                              "error-messages": errors,
                                              label: "Bank",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                              "return-object": "",
                                            },
                                            on: {
                                              change: _vm.onBankAccChange,
                                              input: _vm.onBankAccInput,
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "No. Akaun Bank",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              counter: _vm.selectedBank.max,
                                              maxlength: _vm.selectedBank.max,
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "No. Akaun(BANK)",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.member.bank_acc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "bank_acc",
                                                  $$v
                                                )
                                              },
                                              expression: "member.bank_acc",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      " Alamat Rumah (Tempat Tinggal Sekarang) "
                                    ),
                                  ]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Unit/Lot/No./Tingkat",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              hint: "Contoh: A1-2-3, Blok A",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Unit/Lot/No./Tingkat",
                                              maxLength: "255",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.member.home_unit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "home_unit",
                                                  $$v
                                                )
                                              },
                                              expression: "member.home_unit",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Jalan/Apartment/Taman",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              hint: "Jalan Bahagia Kampung Sentosa",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label:
                                                "Jalan / Apartment / Taman",
                                              maxLength: "255",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.member.home_street,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "home_street",
                                                  $$v
                                                )
                                              },
                                              expression: "member.home_street",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Butiran Tambahan 1",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              hint: "Masukkan butiran alamat sekiranya ada",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label:
                                                "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                              maxLength: "255",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.member.home_line3,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "home_line3",
                                                  $$v
                                                )
                                              },
                                              expression: "member.home_line3",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Butiran Tambahan 2",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              hint: "Masukkan butiran alamat sekiranya ada",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label:
                                                "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                              maxLength: "255",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.member.home_line4,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "home_line4",
                                                  $$v
                                                )
                                              },
                                              expression: "member.home_line4",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Poskod",
                                    rules: "required|numeric",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.mask.postCode,
                                                expression: "mask.postCode",
                                              },
                                            ],
                                            attrs: {
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Poskod",
                                              maxLength: "5",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.member.home_postcode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "home_postcode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.home_postcode",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Bandar", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.city,
                                              "item-text": "display",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              hint: "Bandar mengikut poskod",
                                              label: "Bandar",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            on: {
                                              change: _vm.onHomeCityChange,
                                            },
                                            model: {
                                              value: _vm.member.home_city_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "home_city_id",
                                                  $$v
                                                )
                                              },
                                              expression: "member.home_city_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Negeri", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.states,
                                              "item-text": "name",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Negeri",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.member.home_state_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "home_state_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.home_state_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Status Pemilikan Rumah",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.houseOwnership,
                                              readonly: _vm.isDisabled,
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              label: "Status Pemilikan Rumah",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.member.home_ownership_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "home_ownership_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.home_ownership_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                staticStyle: { "padding-top": "0px" },
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "checbox-decoration",
                                  attrs: {
                                    label:
                                      "Guna alamat utama sebagai alamat surat menyurat",
                                    readonly: _vm.isDisabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.useMainAddress()
                                    },
                                  },
                                  model: {
                                    value: _vm.member.mailing_same_home,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.member,
                                        "mailing_same_home",
                                        $$v
                                      )
                                    },
                                    expression: "member.mailing_same_home",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showMailingAddress,
                                    expression: "showMailingAddress",
                                  },
                                ],
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Alamat Surat Menyurat ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showMailingAddress,
                                    expression: "showMailingAddress",
                                  },
                                ],
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Unit/Lot/No./Tingkat (Surat)",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              hint: "Contoh A1-2-3",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Unit/Lot/No./Tingkat",
                                              maxLength: "255",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.member.mailing_unit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "mailing_unit",
                                                  $$v
                                                )
                                              },
                                              expression: "member.mailing_unit",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showMailingAddress,
                                    expression: "showMailingAddress",
                                  },
                                ],
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Jalan/Apartment/Taman (Surat)",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              hint: "Jalan Bahagia, Kampung Sentosa",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label:
                                                "Jalan / Apartment / Taman",
                                              maxLength: "255",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.member.mailing_street,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "mailing_street",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.mailing_street",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showMailingAddress,
                                    expression: "showMailingAddress",
                                  },
                                ],
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Butiran Tambahan 1 (Surat)",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              hint: "Masukkan butiran alamat sekiranya ada",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label:
                                                "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                              maxLength: "255",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.member.mailing_line3,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "mailing_line3",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.mailing_line3",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showMailingAddress,
                                    expression: "showMailingAddress",
                                  },
                                ],
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Butiran Tambahan 2 (Surat)",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              hint: "Masukkan butiran alamat sekiranya ada",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label:
                                                "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                              maxLength: "255",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.member.mailing_line4,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "mailing_line4",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.mailing_line4",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showMailingAddress,
                                    expression: "showMailingAddress",
                                  },
                                ],
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Poskod (Surat)",
                                    rules: "required|numeric",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.mask.postCode,
                                                expression: "mask.postCode",
                                              },
                                            ],
                                            attrs: {
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Poskod",
                                              maxLength: "5",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.member.mailing_postcode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "mailing_postcode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.mailing_postcode",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showMailingAddress,
                                    expression: "showMailingAddress",
                                  },
                                ],
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Bandar (Surat)",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.city,
                                              "item-text": "display",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              maxLength: "30",
                                              hint: "Bandar mengikut poskod",
                                              label: "Bandar",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            on: {
                                              change: _vm.onMailingCityChange,
                                            },
                                            model: {
                                              value: _vm.member.mailing_city_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "mailing_city_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.mailing_city_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showMailingAddress,
                                    expression: "showMailingAddress",
                                  },
                                ],
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Negeri (Surat)",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.states,
                                              readonly: _vm.isDisabled,
                                              "item-text": "name",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              label: "Negeri",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.member.mailing_state_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "mailing_state_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.mailing_state_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showMailingAddress,
                                    expression: "showMailingAddress",
                                  },
                                ],
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Status Pemilikan (Surat)",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.houseOwnership,
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Status Pemilikan",
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.member.mailing_ownership_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.member,
                                                  "mailing_ownership_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "member.mailing_ownership_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Telefon ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("vue-tel-input-vuetify", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: _vm.mask.phoneMobile,
                                      expression: "mask.phoneMobile",
                                    },
                                  ],
                                  attrs: {
                                    defaultCountry: "MY",
                                    onlyCountries: ["MY"],
                                    readonly: _vm.isDisabled,
                                    placeholder: "Telefon Bimbit",
                                    label: "Telefon Bimbit",
                                    maxlength: 13,
                                    "background-color": _vm.inputColorPhoneNum,
                                    translations: {
                                      countrySelectorLabel: "",
                                      countrySelectorError: "",
                                      phoneNumberLabel: "Telefon Bimbit",
                                      example: "Contoh :",
                                    },
                                    outlined: "",
                                    dense: "",
                                    required: "",
                                  },
                                  on: { input: _vm.onPhoneNumInput },
                                  model: {
                                    value: _vm.member.phone_num,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.member, "phone_num", $$v)
                                    },
                                    expression: "member.phone_num",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: " py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("vue-tel-input-vuetify", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: _vm.mask.phoneLand,
                                      expression: "mask.phoneLand",
                                    },
                                  ],
                                  attrs: {
                                    defaultCountry: "MY",
                                    onlyCountries: ["MY"],
                                    readonly: _vm.isDisabled,
                                    placeholder: "Telefon Rumah",
                                    label: "Telefon Rumah",
                                    hint: "Contoh: 03-1234 5678 / 04-123 4567",
                                    maxlength: 13,
                                    "background-color": _vm.inputColorHomeNum,
                                    translations: {
                                      countrySelectorLabel: "",
                                      countrySelectorError: "",
                                      phoneNumberLabel: "Telefon Rumah",
                                      example: "Contoh :",
                                    },
                                    outlined: "",
                                    dense: "",
                                    required: "",
                                  },
                                  on: { input: _vm.onHomeNumInput },
                                  model: {
                                    value: _vm.member.home_num,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.member, "home_num", $$v)
                                    },
                                    expression: "member.home_num",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("vue-tel-input-vuetify", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: _vm.mask.phoneLand,
                                      expression: "mask.phoneLand",
                                    },
                                  ],
                                  attrs: {
                                    defaultCountry: "MY",
                                    onlyCountries: ["MY"],
                                    readonly: _vm.isDisabled,
                                    placeholder: "Telefon Pejabat",
                                    label: "Telefon Pejabat",
                                    hint: "Contoh: 03-1234 5678 / 04-123 4567",
                                    maxlength: 13,
                                    "background-color":
                                      _vm.inputColorUserOfficeNum,
                                    translations: {
                                      countrySelectorLabel: "",
                                      countrySelectorError: "",
                                      phoneNumberLabel: "Telefon Pejabat",
                                      example: "Contoh :",
                                    },
                                    outlined: "",
                                    dense: "",
                                    required: "",
                                  },
                                  on: { input: _vm.onUserOfficeNumInput },
                                  model: {
                                    value: _vm.member.office_num,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.member, "office_num", $$v)
                                    },
                                    expression: "member.office_num",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Pembiayaan ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: {
                                  cols: "12",
                                  md: "6",
                                  sm: "6",
                                  xs: "2",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Jumlah Pembiayaan Dipohon",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("vuetify-money", {
                                                ref: "loanAmount",
                                                attrs: {
                                                  dense: _vm.moneyDense,
                                                  "error-messages": errors,
                                                  label: _vm.loan.label.amount,
                                                  placeholder:
                                                    _vm.loan.placeholder.amount,
                                                  readonly: true,
                                                  disabled: _vm.moneyDisabled,
                                                  outlined: _vm.moneyOutlined,
                                                  clearable:
                                                    _vm.isMoneyClearable,
                                                  valueWhenIsEmpty:
                                                    _vm.moneyWhenEmpty,
                                                  options: _vm.loanOpt,
                                                  properties:
                                                    _vm.moneyPropertiesRequired,
                                                },
                                                model: {
                                                  value:
                                                    _vm.member
                                                      .financing_req_amount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.member,
                                                      "financing_req_amount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "member.financing_req_amount",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isDisabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCalculator()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Kalkulator")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: {
                                  cols: "12",
                                  md: "6",
                                  sm: "6",
                                  xs: "2",
                                },
                              },
                              [_c("div", { staticClass: "d-flex" })]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: {
                                  cols: "12",
                                  md: "12",
                                  sm: "12",
                                  xs: "12",
                                },
                              },
                              [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [
                                    _c("i", [
                                      _vm._v(
                                        "(Pengiraan Kasar - tertakluk kepada perubahan)"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("vuetify-money", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        dense: _vm.moneyDense,
                                        "error-messages": _vm.errors,
                                        label: _vm.loan.label.inst,
                                        valueWhenIsEmpty:
                                          _vm.moneyWhenEmpty2Dec,
                                        properties: _vm.moneyProperties,
                                        readonly: true,
                                        disabled: _vm.moneyDisabled,
                                        outlined: _vm.moneyOutlined,
                                        options: _vm.loanOpt2,
                                      },
                                      model: {
                                        value: _vm.member.financing_req_inst,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.member,
                                            "financing_req_inst",
                                            $$v
                                          )
                                        },
                                        expression: "member.financing_req_inst",
                                      },
                                    }),
                                    _c("vuetify-money", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        dense: _vm.moneyDense,
                                        "error-messages": _vm.errors,
                                        label: _vm.loan.label.tenure,
                                        valueWhenIsEmpty: _vm.moneyWhenEmpty,
                                        properties: _vm.moneyProperties,
                                        readonly: true,
                                        disabled: _vm.moneyDisabled,
                                        outlined: _vm.moneyOutlined,
                                        options: _vm.tenure,
                                        filled: "",
                                      },
                                      model: {
                                        value: _vm.member.financing_req_tenure,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.member,
                                            "financing_req_tenure",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "member.financing_req_tenure",
                                      },
                                    }),
                                    _c("vuetify-money", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        dense: _vm.moneyDense,
                                        "error-messages": _vm.errors,
                                        label: _vm.loan.label.rate,
                                        valueWhenIsEmpty:
                                          _vm.moneyWhenEmpty2Dec,
                                        properties: _vm.moneyProperties,
                                        readonly: true,
                                        disabled: _vm.moneyDisabled,
                                        outlined: _vm.moneyOutlined,
                                        options: _vm.percent,
                                      },
                                      model: {
                                        value: _vm.member.financing_req_rate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.member,
                                            "financing_req_rate",
                                            $$v
                                          )
                                        },
                                        expression: "member.financing_req_rate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "pr-3 pl-3 pb-2 pt-10",
          staticStyle: {
            display: "flex",
            "align-items": "flex-end",
            "flex-direction": "column",
          },
        },
        [
          !_vm.isDisabled
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { color: "primary", loading: _vm.flagLoading },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Simpan"),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("CalculatorDialog", {
        attrs: {
          title: "Kiraan Jumlah Layak Memohon",
          calculatedResult: _vm.calculatedResult,
          applicationId: _vm.application.id,
          salary: _vm.application.employment.salary,
          deductions: _vm.application.request_deductions,
          employerTypesId: _vm.getEmployerTypeID,
          employerTypes: _vm.employerTypes,
          preCalc: _vm.application.request_calc,
          preData: _vm.financingData,
        },
        on: { onGetResult: _vm.saveCalcResult, calculate: _vm.calculate },
        model: {
          value: _vm.showCalculatorDialog,
          callback: function ($$v) {
            _vm.showCalculatorDialog = $$v
          },
          expression: "showCalculatorDialog",
        },
      }),
      _c("ErrorMsgDialog", {
        attrs: {
          title: "Ralat",
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
          proceedButton: "OK",
        },
        model: {
          value: _vm.showErrorMsgDialog,
          callback: function ($$v) {
            _vm.showErrorMsgDialog = $$v
          },
          expression: "showErrorMsgDialog",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          title: "Info",
          content: _vm.msgINFO.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          proceedButton: "OK",
        },
        model: {
          value: _vm.showInfoMsgDialog,
          callback: function ($$v) {
            _vm.showInfoMsgDialog = $$v
          },
          expression: "showInfoMsgDialog",
        },
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbar.color, elevation: "24", top: "" },
          model: {
            value: _vm.snackbar.visible,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "visible", $$v)
            },
            expression: "snackbar.visible",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [_c("v-icon", [_vm._v(_vm._s(_vm.snackbar.icon))])],
                1
              ),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("div", { staticClass: "mx-3" }, [
                  _vm._v(" " + _vm._s(_vm.snackbar.title) + " "),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
                  ]),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.snackbar.visible = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }