var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card-text", [
        _c(
          "div",
          [
            _c(
              "ValidationObserver",
              { ref: "employmentValidation" },
              [
                _c(
                  "v-form",
                  { staticClass: "font-weight-bold font-size-sm" },
                  [
                    _c(
                      "v-container",
                      { attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Maklumat Pekerjaan ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Nama Majikan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              label: "Nama Majikan",
                                              maxLength: "128",
                                              readonly: _vm.isDisabled,
                                              "error-messages": errors,
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.employer_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "employer_name",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.employer_name",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Kategori Majikan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Kategori Majikan",
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.employerTypes,
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              required: "18",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.employer_type_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "employer_type_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "emp.employer_type_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Pekerjaan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Pekerjaan",
                                              maxLength: "120",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.occupation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "occupation",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.occupation",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Kategori Pekerjaan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Kategori Pekerjaan",
                                              "item-text": "nameMS",
                                              "item-value": "id",
                                              items: _vm.occupationTypes,
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              required: "18",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.occupation_type_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "occupation_type_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "emp.occupation_type_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Bahagian/ Unit",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Bahagian/ Unit",
                                              maxLength: "120",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.department,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "department",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.department",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Pendapatan Sebulan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("vuetify-money", {
                                            attrs: {
                                              dense: _vm.moneyDense,
                                              "error-messages": errors,
                                              label: _vm.salaryLabel,
                                              valueWhenIsEmpty:
                                                _vm.moneyNumEmpty,
                                              placeholder:
                                                _vm.salaryPlaceholder,
                                              properties:
                                                _vm.moneySalaryProperties,
                                              clearable: false,
                                              readonly: true,
                                              disabled: _vm.moneyDisabled,
                                              outlined: _vm.moneyOutlined,
                                              options: _vm.salaryOpt,
                                            },
                                            model: {
                                              value: _vm.emp.salary,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.emp, "salary", $$v)
                                              },
                                              expression: "emp.salary",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Tarikh Mula Bekerja",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "error-messages": errors,
                                                "close-on-content-click": false,
                                                "nudge-right": 40,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "min-width": "290px",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "mask",
                                                                    rawName:
                                                                      "v-mask",
                                                                    value:
                                                                      _vm.mask_date,
                                                                    expression:
                                                                      "mask_date",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  value:
                                                                    _vm.computedDateOfService,
                                                                  label:
                                                                    "Tarikh Mula Bekerja",
                                                                  readonly:
                                                                    _vm.isDisabled,
                                                                  outlined: "",
                                                                  dense: "",
                                                                },
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.dateOfServiceMenu,
                                                callback: function ($$v) {
                                                  _vm.dateOfServiceMenu = $$v
                                                },
                                                expression: "dateOfServiceMenu",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  locale: "ms-my",
                                                  max: _vm.maxDateEligibleWork,
                                                  readonly: _vm.isDisabled,
                                                  disabled: _vm.isDisabled,
                                                },
                                                on: {
                                                  change:
                                                    _vm.onCommenceDateChange,
                                                  input: function ($event) {
                                                    _vm.dateOfServiceMenu = false
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.emp.commencement_date,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.emp,
                                                      "commencement_date",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "emp.commencement_date",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Tahun Perkhidmatan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              "error-messages": errors,
                                              label: "Tahun Perkhidmatan",
                                              hint: "Dikira berdasarkan dari tarikh mula bekerja",
                                              required: "",
                                              readonly: "",
                                              outlined: "",
                                              filled: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.year_of_service,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "year_of_service",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.year_of_service",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Alamat Syarikat ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Unit/Lot/No./Tingkat",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Unit/Lot/No./Tingkat",
                                              hint: "Contoh A1-2-3",
                                              maxLength: "255",
                                              readonly: _vm.isDisabled,
                                              "error-messages": errors,
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.office_unit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "office_unit",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.office_unit",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Bangunan/Jalan",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Bangunan/Jalan",
                                              hint: "Wisma Persekutuan, Jalan Damansara",
                                              maxLength: "255",
                                              readonly: _vm.isDisabled,
                                              "error-messages": errors,
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.office_street,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "office_street",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.office_street",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Butiran Tambahan 1",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              label:
                                                "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                              hint: "Masukkan butiran alamat sekiranya ada",
                                              maxLength: "255",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.office_line3,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "office_line3",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.office_line3",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "12" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Butiran Tambahan 2",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            staticClass: "ucase-text",
                                            attrs: {
                                              hint: "Masukkan butiran alamat sekiranya ada",
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label:
                                                "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                              maxLength: "255",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.office_line4,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "office_line4",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.office_line4",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Poskod", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.mask_postCode,
                                                expression: "mask_postCode",
                                              },
                                            ],
                                            attrs: {
                                              label: "Poskod",
                                              maxLength: "5",
                                              readonly: _vm.isDisabled,
                                              "error-messages": errors,
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.office_postcode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "office_postcode",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.office_postcode",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Bandar", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Bandar",
                                              hint: "Bandar mengikut poskod",
                                              "item-text": "display",
                                              "item-value": "id",
                                              items: _vm.city,
                                              readonly: _vm.isDisabled,
                                              "error-messages": errors,
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            on: {
                                              change: _vm.onOfficeCityChange,
                                            },
                                            model: {
                                              value: _vm.emp.office_city_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "office_city_id",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.office_city_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Negeri", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Negeri",
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.states,
                                              readonly: _vm.isDisabled,
                                              "error-messages": errors,
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.office_state_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "office_state_id",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.office_state_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [_vm._v(" Telefon ")]
                                ),
                                _c("v-divider", { staticClass: "mt-0" }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "5" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Telefon Pejabat",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("vue-tel-input-vuetify", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.mask_phoneLand,
                                                expression: "mask_phoneLand",
                                              },
                                            ],
                                            attrs: {
                                              label: "No. Telefon Pejabat",
                                              hint: "Contoh: 03-1234 5678 / 04-123 4567",
                                              placeholder: "No.Telefon Pejabat",
                                              defaultCountry: "MY",
                                              onlyCountries: ["MY"],
                                              readonly: _vm.isDisabled,
                                              "error-messages": errors,
                                              "background-color":
                                                _vm.inputColorOfficeNum,
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            on: { input: _vm.onOfficeNumInput },
                                            model: {
                                              value: _vm.emp.office_num,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "office_num",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.office_num",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "2" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Sambungan", rules: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.mask_postCode,
                                                expression: "mask_postCode",
                                              },
                                            ],
                                            attrs: {
                                              "error-messages": errors,
                                              readonly: _vm.isDisabled,
                                              label: "Sambungan",
                                              maxLength: "6",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.emp.office_num_ext,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "office_num_ext",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.office_num_ext",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "5" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Fax Pejabat", rules: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("vue-tel-input-vuetify", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.mask_phoneLand,
                                                expression: "mask_phoneLand",
                                              },
                                            ],
                                            attrs: {
                                              hint: "Contoh: 03-1234 5678 / 04-123 4567",
                                              label: "No. Faks",
                                              placeholder: "No. Faks",
                                              defaultCountry: "MY",
                                              onlyCountries: ["MY"],
                                              readonly: _vm.isDisabled,
                                              "error-messages": errors,
                                              "background-color":
                                                _vm.inputColorOfficeFax,
                                              outlined: "",
                                              dense: "",
                                            },
                                            on: { input: _vm.onOfficeFaxInput },
                                            model: {
                                              value: _vm.emp.office_fax,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "office_fax",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.office_fax",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "E-Mel Majikan",
                                    rules: "required|email",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "E-Mel Majikan :",
                                              hint: "Contoh: jabatan@organisasi.gov.my",
                                              placeholder:
                                                "jabatan@organisasi.gov.my",
                                              "error-messages": errors,
                                              outlined: "",
                                              dense: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.emp.employer_email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.emp,
                                                  "employer_email",
                                                  $$v
                                                )
                                              },
                                              expression: "emp.employer_email",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "pr-3 pl-3 pb-2 pt-10",
          staticStyle: {
            display: "flex",
            "align-items": "flex-end",
            "flex-direction": "column",
          },
        },
        [
          !_vm.isDisabled
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { color: "primary", loading: _vm.flagLoading },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Simpan"),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("ErrorMsgDialog", {
        attrs: {
          title: "Ralat",
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
          proceedButton: "OK",
        },
        model: {
          value: _vm.openErrorMsgDialog,
          callback: function ($$v) {
            _vm.openErrorMsgDialog = $$v
          },
          expression: "openErrorMsgDialog",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          title: "Info",
          content: _vm.msgINFO.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          proceedButton: "OK",
        },
        model: {
          value: _vm.openInfoMsgDialog,
          callback: function ($$v) {
            _vm.openInfoMsgDialog = $$v
          },
          expression: "openInfoMsgDialog",
        },
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbar.color, elevation: "24", top: "" },
          model: {
            value: _vm.snackbar.visible,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "visible", $$v)
            },
            expression: "snackbar.visible",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [_c("v-icon", [_vm._v(_vm._s(_vm.snackbar.icon))])],
                1
              ),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("div", { staticClass: "mx-3" }, [
                  _vm._v(" " + _vm._s(_vm.snackbar.title) + " "),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
                  ]),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.snackbar.visible = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }