<template>
  <div>
    <b-card-text>
      <div>
        <ValidationObserver ref="memberValidation">
          <v-form class="font-weight-bold font-size-sm">
            <v-container fluid>
              <v-row justify="space-between">
                <v-col cols="12" md="12">
                  <h5 class="primary--text font-weight-bold">
                    Maklumat Diri
                  </h5>
                  <v-divider class="mt-0"></v-divider>
                </v-col>

                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nama Pemohon"
                    rules="required"
                  >
                    <v-text-field
                      v-model="member.full_name"
                      label="Nama Pemohon Seperti dalam MyKad / Kad Pengenalan"
                      hint="Nama Pemohon Seperti dalam MyKad / Kad Pengenalan"
                      class="ucase-text"
                      maxLength="128"
                      :readonly="isDisabled"
                      :error-messages="errors"
                      @focus="$event.target.select()"
                      outlined
                      dense
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!--
                <v-col class="py-0" cols="12" md="3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="No. Kad Pengenalan"
                    rules="required"
                  >
                    <v-text-field
                      v-model="member.nric_display"
                      label="No. Kad Pengenalan :"
                      v-mask="mask.icNum"
                      required="1"
                      outlined
                      readonly
                      filled
                      dense
                    />
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="No. Telefon"
                    rules="required"
                  >
                    <v-text-field
                      v-model="member.phone_num_display"
                      label="No. Telefon :"
                      required="1"
                      outlined
                      readonly
                      filled
                      dense
                    />
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="E-mel"
                    rules="required"
                  >
                    <v-text-field
                      v-model="member.email"
                      label="E-mel :"
                      required="1"
                      outlined
                      readonly
                      filled
                      dense
                    />
                  </ValidationProvider>
                </v-col>
                -->

                <v-col class="py-0" cols="12" md="3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Gelaran"
                    rules="required"
                  >
                    <v-select
                      v-model="member.salute_id"
                      label="Gelaran"
                      item-text="nameMS"
                      item-value="id"
                      :items="salute"
                      :readonly="isDisabled"
                      :error-messages="errors"
                      outlined
                      required
                      dense
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="4">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Tarikh Lahir"
                    rules="required"
                  >
                    <!-- <v-menu v-model="birthDateMenu" :error-messages="errors" :close-on-content-click="false"
                      :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }"> -->
                    <v-text-field
                      :value="computedMemberBirthDate"
                      label="Tarikh Lahir"
                      hint="Tarikh Lahir diperoleh daripada Nombor Kad Pengenalan"
                      :disable="isDisabled"
                      :error-messages="errors"
                      readonly
                      outlined
                      filled
                      dense
                    ></v-text-field>
                    <!-- </template>
                      <v-date-picker v-model="member.birth_date" :disable="isDisabled" :readonly="isDisabled"
                        @input="birthDateMenu = false">
                      </v-date-picker>
                    </v-menu> -->
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="5">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Jantina"
                    rules="required"
                  >
                    <v-select v-model="member.gender_id" :items="genders" :readonly="isDisabled" item-text="nameMS"
                      item-value="id" :error-messages="errors" label="Jantina" outlined dense required filled>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Bangsa"
                    rules="required"
                  >
                    <v-select v-model="member.race_id" :items="races" :readonly="isDisabled" item-text="nameMS"
                      item-value="id" :error-messages="errors" label="Bangsa" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Agama"
                    rules="required"
                  >
                    <v-select v-model="member.religion_id" :items="religions" :readonly="isDisabled" item-text="nameMS"
                      item-value="id" :error-messages="errors" label="Agama" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Status Perkahwinan"
                    rules="required"
                  >
                    <v-select v-model="member.marital_status_id" :items="maritalStatus" :readonly="isDisabled"
                      item-text="nameMS" item-value="id" :error-messages="errors" label="Status Perkahwinan" outlined
                      dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Bilangan Anak">
                    <v-text-field v-model="member.children" :error-messages="errors" :readonly="isDisabled"
                      label="Bilangan Anak (jika ada)" outlined dense>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Tahap Pendidikan Tertinggi"
                    rules="required"
                  >
                    <v-select v-model="member.education_level_id" :items="educationLevel" :readonly="isDisabled"
                      item-text="nameMS" item-value="id" :error-messages="errors" label="Tahap Pendidikan Tertinggi"
                      outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nama Ibu"
                    rules="required"
                  >
                    <v-text-field v-model="member.mother_name" label="Nama Ibu" class="ucase-text"
                      :error-messages="errors" :readonly="isDisabled" outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" md="12">
                  <h5 class="primary--text font-weight-bold">
                    Maklumat Bank
                  </h5>
                  <v-divider class="mt-0"></v-divider>
                </v-col>

                <v-col class="py-0" cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Bank"
                    rules="required"
                  >
                    <v-select :value="getBank" :v-model="member.banks_id" :items="banks" :readonly="isDisabled"
                      item-text="name" item-value="id" item-color="primary" :error-messages="errors" label="Bank" outlined
                      dense required return-object v-on:change="onBankAccChange" @input="onBankAccInput">
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="No. Akaun Bank"
                    rules="required"
                  >
                    <v-text-field v-model="member.bank_acc" :counter="selectedBank.max" :maxlength="selectedBank.max"
                      :error-messages="errors" :readonly="isDisabled" label="No. Akaun(BANK)" outlined dense
                      required></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" md="12">
                  <h5 class="primary--text font-weight-bold">
                    Alamat Rumah (Tempat Tinggal Sekarang)
                  </h5>
                  <v-divider class="mt-0"></v-divider>
                </v-col>

                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Unit/Lot/No./Tingkat"
                    rules="required"
                  >
                    <v-text-field hint="Contoh: A1-2-3, Blok A" v-model="member.home_unit" :error-messages="errors"
                      :readonly="isDisabled" label="Unit/Lot/No./Tingkat" maxLength="255" outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Jalan/Apartment/Taman"
                    rules="required"
                  >
                    <v-text-field hint="Jalan Bahagia Kampung Sentosa" v-model="member.home_street"
                      :error-messages="errors" :readonly="isDisabled" label="Jalan / Apartment / Taman" maxLength="255"
                      outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Butiran Tambahan 1" rules="">
                    <v-text-field hint="Masukkan butiran alamat sekiranya ada" v-model="member.home_line3"
                      :error-messages="errors" :readonly="isDisabled" label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                      class="ucase-text" maxLength="255" outlined dense>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Butiran Tambahan 2" rules="">
                    <v-text-field hint="Masukkan butiran alamat sekiranya ada" v-model="member.home_line4"
                      :error-messages="errors" :readonly="isDisabled" label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                      class="ucase-text" maxLength="255" outlined dense></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="4">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Poskod" rules="required|numeric">
                    <v-text-field v-model="member.home_postcode" :error-messages="errors" :readonly="isDisabled"
                      v-mask="mask.postCode" label="Poskod" maxLength="5" outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="8">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Bandar"
                    rules="required"
                  >
                    <v-select v-model="member.home_city_id" :items="city" item-text="display" item-value="id"
                      :error-messages="errors" :readonly="isDisabled" hint="Bandar mengikut poskod" label="Bandar"
                      outlined dense required v-on:change="onHomeCityChange">
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="8">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Negeri"
                    rules="required"
                  >
                    <v-select v-model="member.home_state_id" :items="states" item-text="name" item-value="id"
                      :error-messages="errors" :readonly="isDisabled" label="Negeri" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="8">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Status Pemilikan Rumah"
                    rules="required"
                  >
                    <v-select v-model="member.home_ownership_id" :items="houseOwnership" :readonly="isDisabled"
                      item-text="nameMS" item-value="id" :error-messages="errors" label="Status Pemilikan Rumah" outlined
                      dense required></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12" style="padding-top: 0px">
                  <v-checkbox class="checbox-decoration" label="Guna alamat utama sebagai alamat surat menyurat"
                    v-model="member.mailing_same_home" :readonly="isDisabled" @click="useMainAddress()"></v-checkbox>
                </v-col>

                <v-col cols="12" md="12" v-show="showMailingAddress">
                  <h5 class="primary--text font-weight-bold">
                    Alamat Surat Menyurat
                  </h5>
                  <v-divider class="mt-0"></v-divider>
                </v-col>
                <v-col class="py-0" cols="12" md="12" v-show="showMailingAddress">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Unit/Lot/No./Tingkat (Surat)"
                    rules="required"
                  >
                    <v-text-field hint="Contoh A1-2-3" v-model="member.mailing_unit" :error-messages="errors"
                      :readonly="isDisabled" label="Unit/Lot/No./Tingkat" maxLength="255" outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12" v-show="showMailingAddress">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Jalan/Apartment/Taman (Surat)"
                    rules="required"
                  >
                    <v-text-field hint="Jalan Bahagia, Kampung Sentosa" v-model="member.mailing_street"
                      :error-messages="errors" :readonly="isDisabled" label="Jalan / Apartment / Taman" maxLength="255"
                      outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12" v-show="showMailingAddress">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Butiran Tambahan 1 (Surat)" rules="">
                    <v-text-field hint="Masukkan butiran alamat sekiranya ada" v-model="member.mailing_line3"
                      :error-messages="errors" :readonly="isDisabled" label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                      class="ucase-text" maxLength="255" outlined dense>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12" v-show="showMailingAddress">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Butiran Tambahan 2 (Surat)" rules="">
                    <v-text-field hint="Masukkan butiran alamat sekiranya ada" v-model="member.mailing_line4"
                      :error-messages="errors" :readonly="isDisabled" label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                      class="ucase-text" maxLength="255" outlined dense></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="4" v-show="showMailingAddress">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Poskod (Surat)" rules="required|numeric">
                    <v-text-field v-model="member.mailing_postcode" :error-messages="errors" :readonly="isDisabled"
                      v-mask="mask.postCode" label="Poskod" maxLength="5" outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="8" v-show="showMailingAddress">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Bandar (Surat)"
                    rules="required"
                  >
                    <v-select v-model="member.mailing_city_id" :items="city" item-text="display" item-value="id"
                      :error-messages="errors" :readonly="isDisabled" maxLength="30" hint="Bandar mengikut poskod"
                      label="Bandar" outlined dense required v-on:change="onMailingCityChange">
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="8" v-show="showMailingAddress">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Negeri (Surat)"
                    rules="required"
                  >
                    <v-select v-model="member.mailing_state_id" :items="states" :readonly="isDisabled" item-text="name"
                      item-value="id" :error-messages="errors" label="Negeri" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="8" v-show="showMailingAddress">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Status Pemilikan (Surat)"
                    rules="required"
                  >
                    <v-select v-model="member.mailing_ownership_id" :items="houseOwnership" item-text="nameMS"
                      item-value="id" :error-messages="errors" :readonly="isDisabled" label="Status Pemilikan" outlined
                      dense required></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" md="12">
                  <h5 class="primary--text font-weight-bold">
                    Telefon
                  </h5>
                  <v-divider class="mt-0"></v-divider>
                </v-col>

                <v-col class="py-0" cols="12" md="4">
                  <vue-tel-input-vuetify v-model="member.phone_num" v-mask="mask.phoneMobile" defaultCountry="MY"
                    :onlyCountries="['MY']" :readonly="isDisabled" placeholder="Telefon Bimbit" label="Telefon Bimbit"
                    @input="onPhoneNumInput" :maxlength="13" :background-color="inputColorPhoneNum" :translations="{
                      countrySelectorLabel: '',
                      countrySelectorError: '',
                      phoneNumberLabel: 'Telefon Bimbit',
                      example: 'Contoh :',
                    }" outlined dense required></vue-tel-input-vuetify>
                </v-col>

                <v-col class=" py-0" cols="12" md="4">
                  <vue-tel-input-vuetify v-model="member.home_num" v-mask="mask.phoneLand" defaultCountry="MY"
                    :onlyCountries="['MY']" :readonly="isDisabled" placeholder="Telefon Rumah" label="Telefon Rumah"
                    hint="Contoh: 03-1234 5678 / 04-123 4567" :maxlength="13" :background-color="inputColorHomeNum"
                    @input="onHomeNumInput" :translations="{
                      countrySelectorLabel: '',
                      countrySelectorError: '',
                      phoneNumberLabel: 'Telefon Rumah',
                      example: 'Contoh :',
                    }
                      " outlined dense required></vue-tel-input-vuetify>
                </v-col>

                <v-col class="py-0" cols="12" md="4">
                  <vue-tel-input-vuetify v-model="member.office_num" v-mask="mask.phoneLand" defaultCountry="MY"
                    :onlyCountries="['MY']" :readonly="isDisabled" placeholder="Telefon Pejabat" label="Telefon Pejabat"
                    hint="Contoh: 03-1234 5678 / 04-123 4567" :maxlength="13" :background-color="inputColorUserOfficeNum"
                    @input="onUserOfficeNumInput" :translations="{
                      countrySelectorLabel: '',
                      countrySelectorError: '',
                      phoneNumberLabel: 'Telefon Pejabat',
                      example: 'Contoh :',
                    }
                      " outlined dense required></vue-tel-input-vuetify>
                </v-col>

                <v-col cols="12" md="12">
                  <h5 class="primary--text font-weight-bold">
                    Pembiayaan
                  </h5>
                  <v-divider class="mt-0"></v-divider>
                </v-col>

                <v-col class="py-0" cols="12" md="6" sm="6" xs="2">
                  <div class="d-flex">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Jumlah Pembiayaan Dipohon"
                      rules="required"
                    >
                      <vuetify-money ref="loanAmount" :dense="moneyDense" :error-messages="errors" v-model="member.financing_req_amount"
                        v-bind:label="loan.label.amount" v-bind:placeholder="loan.placeholder.amount" v-bind:readonly="true"
                        v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined"
                        v-bind:clearable="isMoneyClearable" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                        v-bind:options="loanOpt" v-bind:properties="moneyPropertiesRequired"/>
                    </ValidationProvider>
                    <v-btn class="ml-2" color="primary" :disabled="isDisabled" @click="openCalculator()"><span
                        class="font-weight-bold">Kalkulator</span>
                    </v-btn>
                  </div>
                </v-col>
                <v-col class="py-0" cols="12" md="6" sm="6" xs="2">
                  <div class="d-flex">
                  </div>
                </v-col>
                <v-col class="py-0" cols="12" md="12" sm="12" xs="12">
                  <h6 class="primary--text font-weight-bold">
                    <i>(Pengiraan Kasar - tertakluk kepada perubahan)</i>
                  </h6>
                  <div class="d-flex">
                    <vuetify-money
                      v-model="member.financing_req_inst"
                      class="mr-2"
                      :dense="moneyDense"
                      :error-messages="errors"
                      v-bind:label="loan.label.inst"
                      v-bind:valueWhenIsEmpty="moneyWhenEmpty2Dec"
                      v-bind:properties="moneyProperties"
                      v-bind:readonly="true"
                      v-bind:disabled="moneyDisabled"
                      v-bind:outlined="moneyOutlined"
                      v-bind:options="loanOpt2"
                    />

                    <vuetify-money
                      v-model="member.financing_req_tenure"
                      class="mr-2"
                      :dense="moneyDense"
                      :error-messages="errors"
                      v-bind:label="loan.label.tenure"
                      v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                      v-bind:properties="moneyProperties"
                      v-bind:readonly="true"
                      v-bind:disabled="moneyDisabled"
                      v-bind:outlined="moneyOutlined"
                      v-bind:options="tenure"
                      filled
                    />

                    <vuetify-money
                      v-model="member.financing_req_rate"
                      class="mr-2"
                      :dense="moneyDense"
                      :error-messages="errors"
                      v-bind:label="loan.label.rate"
                      v-bind:valueWhenIsEmpty="moneyWhenEmpty2Dec"
                      v-bind:properties="moneyProperties"
                      v-bind:readonly="true"
                      v-bind:disabled="moneyDisabled"
                      v-bind:outlined="moneyOutlined"
                      v-bind:options="percent"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
      </div>
    </b-card-text>

    <div class="pr-3 pl-3 pb-2 pt-10" style="display: flex; align-items: flex-end; flex-direction: column;">
      <v-btn v-if="!isDisabled" class="ml-4" color="primary" :loading="flagLoading" @click="save()">
        <span class="font-weight-bold">Simpan</span>
      </v-btn>
    </div>

    <CalculatorDialog
      v-model="showCalculatorDialog"
      title="Kiraan Jumlah Layak Memohon"
      :calculatedResult="calculatedResult"
      :applicationId="application.id"
      :salary="application.employment.salary"
      :deductions="application.request_deductions"
      :employerTypesId="getEmployerTypeID"
      :employerTypes="employerTypes"
      :preCalc="application.request_calc"
      :preData="financingData"
      @onGetResult="saveCalcResult"
      @calculate="calculate"
    ></CalculatorDialog>
      <!-- :calculatedResult="calculatedResult" -->
      <!-- :tenure_year="tenureInYear" -->
      <!-- backButton="Batal" -->
      <!-- :showBackButton="true" -->
      <!-- :proceedButton="calculator.total.toString()" -->

    <ErrorMsgDialog v-model="showErrorMsgDialog" title="Ralat" :content="msgERR.message" :showProceedButton="true"
      :showBackButton="false" :delayOnProceed="false" :isTypeError="true" proceedButton="OK"></ErrorMsgDialog>

    <InfoMsgDialog v-model="showInfoMsgDialog" title="Info" :content="msgINFO.message" :showProceedButton="true"
      :showBackButton="false" :delayOnProceed="false" proceedButton="OK"></InfoMsgDialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" elevation="24" top>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="1">
          <v-icon>{{ snackbar.icon }}</v-icon>
        </v-col>
        <v-col cols="10">
          <div class="mx-3">
            {{ snackbar.title }}
            <p class="mb-0">
              {{ snackbar.text }}
            </p>
          </div>
        </v-col>
        <v-col cols="1">
          <v-btn icon @click="snackbar.visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>

  </div>
</template>

<script>
import dayjs from "dayjs";

import {
  mapGetters,
} from "vuex";

import {
  SET_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module";

import {
  GET_USER,
} from "@/core/services/store/user.module";

import {
  UPDATE_MEMBER,
} from "@/core/services/store/application.module";

import {
  GET_MEMBER,
  CREATE_MEMBER,
  IMPORT_APPLICATION_TO_STORE,
  SET_MEMBER,
  SET_EMPLOYMENT,
  SET_APPLICATION,
  UPDATE_APPLICATION_FINANCING,
} from "@/core/services/store/application.module";

import {
  CALCULATE_APP,
  SET_CALC_RESULT,
} from "@/core/services/store/calculator.module";

import {
  max,
  email,
  numeric,
  required,
} from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import {
  blankMember,
  blankEmployment,
  convUpperCase,
  errorMessages,
} from "@/core/services/helper.service";

// import InfoMsgDialog from "@/view/pages/widgets/MessageDialog.vue";
// import ErrorMsgDialog from "@/view/pages/widgets/MessageDialog.vue";
import CalculatorDialog from "@/view/pages/widgets/CalculatorDialog.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});

extend("email", {
  ...email,
  message: "E-mel mestilah sah",
});


export default {
  name: "MemberDetails",

  components: {
    // InfoMsgDialog,
    // ErrorMsgDialog,
    CalculatorDialog,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    propData: {
      type: Object,
      default: () => { return {}; },
    },

    propReadOnly: {
      type: Boolean,
      default: false,
    },

  },

  data() {
    return {
      theData: {
        member: blankMember(),
        employment: blankEmployment(),
      },

      errors: "",

      city: [],
      races: [],
      banks: [],
      genders: [],
      salute: [],
      states: [],
      religions: [],
      relations: [],
      employerTypes: [],
      maritalStatus: [],
      houseOwnership: [],
      educationLevel: [],
      occupationTypes: [],
      cityHomeScoped: [],
      cityMailingScoped: [],
      selectedBank: {},

      overlay: true,
      flagLoading: false,
      birthDateMenu: false,
      showInfoMsgDialog: false,
      // showMailingAddress: true,
      showErrorMsgDialog: false,
      showCalculatorDialog: false,
      // useAddressAsMailingAddress: false,

      mask: {
        mask_icNum: "######-##-####",
        icNum: "######-##-####",
        bankAcc: "####################",
        postCode: "#####",
        phoneLand: "##-########",
        phoneMobile: "###-########",
      },

      loan: {
        label: {
          rate: "Kadar (%)",
          inst: "Ansuran Bulanan",
          tenure: "Tempoh (Bulan)",
          amount: "Jumlah Pembiayaan Dipohon",
        },
        hint: {
          amount: "Minimum RM 1,000 & Maksima RM 100,000",
        },
        placeholder: {
          amount: "RM 1,000 ~ RM 100,000",
        },
      },

      calculator: {
        total: 0,
      },

      phone: {
        phone_num: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },
        home_num: {
          number: "",
          valid: false,
          mobile: true,
          country: undefined,
          color: "white",
        },
        user_office_num: {
          number: "",
          valid: false,
          mobile: true,
          country: undefined,
          color: "white",
        },
      },

      loanOpt: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 0,
        min: 1000,
        max: 1000000,
      },
      loanOpt2: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 2,
        min: 1000,
        max: 200000,
      },
      percent: {
        locale: "ms-MY",
        prefix: "",
        suffix: "%",
        length: 5,
        precision: 2,
        min: 0,
        max: 100,
      },
      tenure: {
        locale: "ms-MY",
        prefix: "",
        suffix: "BULAN",
        length: 2,
        precision: 0,
        min: 12,
        max: 240,
      },

      snackbar: {
        icon: "",
        color: "",
        title: "",
        text: "",
        visible: false,
      },

      msgERR: {
        message: "",
        nric: "",
        run: 0,
      },

      msgINFO: {
        message: "",
        nric: "",
        run: 0,
      },

      moneyDense: true,
      moneyProperties: {
        error: true,
        "hide-details": true,
      },

      moneyPropertiesRequired: {
        error: true,
        "hide-details": true,
        rules: "required",
      },

      moneyReadOnly: false,
      moneyDisabled: false,
      moneyOutlined: true,
      moneyClearable: true,
      moneyNumEmpty: "",
      moneyWhenEmpty: "0",
      moneyWhenEmpty2Dec: "0.00",
      exceptionList: ["email", "employer_email", "financing_calc", "signature"],
      inputColorOK: "cyan lighten-5",
      inputColorNG: "red lighten-4",
    }
  },

  watch: {
    propData: function (newValue, oldValue) {
      this.theData = newValue;
    },

  },

  computed: {
    ...mapGetters([
      "currentUser",
      "calculatedResult",
      "currentUserRoles",
      // "application",
    ]),

    showMailingAddress() {
      return !!!this.member.mailing_same_home;
    },

    application: {
      get: function () {
        return this.theData;
      },

      set: function (val) {
        this.theData = val;
        //nfh-console.log('COMPUTED val, this.theData: ', val, this.theData);
      }
    },

    member: {
      get: function () {
        return this.theData.member;
      },

      set: function (val) {
        this.theData.member = val;
        //nfh-console.log('COMPUTED val, this.theData.member: ', val, this.theData.member);
      }
    },

    employment: {
      get: function () {
        return this.theData.employment;
      },

      set: function (val) {
        this.theData.employment = val;
        //nfh-console.log('COMPUTED val, this.theData.employment: ', val, this.theData.employment);
      }
    },

    financingData() {
      return {
        deds: this.application?.request_deductions,
        inst: this.application?.request_inst,
        rate: this.application?.request_rate,
        amount: this.application?.request_amount,
        tenure: this.application?.request_tenure,
      };
    },

    currentId() {
      return parseInt(this.$route.params.id) ?? 0;
    },

    getBank() {
      this.banks.find((_bank) => {
        if (_bank.id == this.member.banks_id) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selectedBank = _bank;
          return true;
        }
      }, this);
      return this.selectedBank.id;
    },

    getSalary() {
      return parseFloat(this.application.employment.salary);
    },

    getEmployerID() {
      return parseInt(this.application.employment.employerId);
    },

    getEmployerTypeID() {
      return parseInt(this.application.employment.employer_type_id);
    },

    tenureInYear() {
      return Math.max(this.application.request_tenure / 12, 0);
    },

    inputValidPhoneNum() {
      return this.phone.phone_num.valid;
    },

    inputColorPhoneNum() {
      return this.phone.phone_num.color;
    },

    inputColorHomeNum() {
      return this.phone.home_num.color;
    },

    inputColorUserOfficeNum() {
      return this.phone.user_office_num.color;
    },

    computedMemberBirthDate() {
      return this.member.birth_date
        ? dayjs(this.member.birth_date).format("DD/MM/YYYY")
        : "";
    },

    isMoneyClearable() {
      return !!(this.isDisabled ? false : false);
    },

    isMoneyDisabled() {
      return !!this.isDisabled;
    },

    isMoneyReadOnly() {
      return !!this.isDisabled;
    },

    isDisabled() {
      return !!this.propReadOnly;
    },

    isLoadingDSR() {
      return !!this.flagLoading;
    }
  },

  methods: {
    async init() {
      //nfh-console.log(' IN: INIT');
      //nfh-console.log('OUT: INIT');
    },

    async onValidate() {
      return new Promise((resolve, reject) => {
        this.$refs.memberValidation.validate()
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
      });
    },

    onBankAccChange(value) {
      this.mask.bankAcc = "#".repeat(value.max);
      this.member.bank_name = value.name;
      this.member.banks_id = value.id;
    },

    onBankAccInput() {
      this.mask.bankAcc = "#".repeat(this.selectedBank.max);
      this.member.bank_name = this.selectedBank.name;
      this.member.banks_id = this.selectedBank.id;
    },

    useMainAddress() {
      // this.showMailingAddress = !this.showMailingAddress;
      // this.showMailingAddress = !this.member.mailing_same_home;
      this.member.mailing_unit = this.member.home_unit;
      this.member.mailing_street = this.member.home_street;
      this.member.mailing_line3 = this.member.home_line3;
      this.member.mailing_line4 = this.member.home_line4;
      this.member.mailing_postcode = this.member.home_postcode;
      this.member.mailing_city_id = this.member.home_city_id;
      this.member.mailing_state_id = this.member.home_state_id;
      this.member.mailing_ownership_id = this.member.home_ownership_id;
    },

    onPhoneNumInput(formattedNumber, { number, valid, country }) {
      this.phone.phone_num.country = country;
      this.phone.phone_num.number = number.national;
      this.phone.phone_num.mobile = parseInt(number?.national?.split('-')[0]) > 9;
      this.phone.phone_num.valid = valid && this.phone.phone_num.mobile;
      this.phone.phone_num.color = (this.phone.phone_num.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onHomeNumInput(formattedNumber, { number, valid, country }) {
      this.phone.home_num.country = country;
      this.phone.home_num.number = number.national;
      this.phone.home_num.mobile = parseInt(this.phone?.home_num?.number?.split('-')[0]) > 9;
      this.phone.home_num.valid = valid && !this.phone.home_num.mobile;
      this.phone.home_num.color = (this.phone.home_num.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onUserOfficeNumInput(formattedNumber, { number, valid, country }) {
      this.phone.user_office_num.country = country;
      this.phone.user_office_num.number = number.national;
      this.phone.user_office_num.mobile = parseInt(this.phone.user_office_num?.number.split('-')[0]) > 9;
      this.phone.user_office_num.valid = valid && !this.phone.user_office_num?.mobile;
      this.phone.user_office_num.color = (this.phone.user_office_num.valid) ? this.inputColorOK : this.inputColorNG;
      // this.employment.office_num = number.national;
    },

    onHomeCityChange(value) {
      if (
        this.city.find((ct) => {
          if (ct.id == value) {
            this.member.home_state_id = ct.state_id;
          }
        })
      );
    },

    onMailingCityChange(value) {
      if (
        this.city.find((ct) => {
          if (ct.id == value) {
            this.member.mailing_state_id = ct.state_id;
          }
        })
      );
    },

    onHomeStateChange(value) {
      this.cityHomeScoped = value
        ? this.city.slice().filter((ct) => ct.state_id === value)
        : this.city.slice();
    },

    onMailingStateChange(value) {
      this.cityMailingScoped = value
        ? this.city.slice().filter((ct) => ct.state_id === value)
        : this.city.slice();
    },

    async calculate(value) {
      // const params = {
      //   salary: +value.salary,
      //   deductions: value.deductions,
      //   employer: value.employerId,
      // };

      // console.log("CALCULATE_APP params: ", params)

      // await this.$store.dispatch(CALCULATE_APP, params)
      //   .then((resp) => {
      //     console.log("CALCULATE_APP result: ", resp)
      //     this.$store.commit(SET_CALC_RESULT, resp.data);
      //   });
    },

    saveCalcResult(calcData) { //total, rate, tenure, inst, deds, salary) {
      // this.calculator.total = total;
      // this.member.financing_req_amount = total;
      // this.member.financing_req_tenure = tenure;
      // this.member.financing_req_rate = rate;
      // this.member.financing_req_inst = inst;
      // this.member.financing_req_deds = deds;
      // this.application.request_tenure = tenure;
      // this.application.request_amount = total;
      // this.application.request_rate = rate;
      // this.application.request_inst = inst;
      // this.application.request_deductions = deds;
      // this.application.employment.salary = salary;
      // this.showCalculatorDialog = false;

      //nfh-console.log(" IN: saveCalcResult");
      //nfh-console.log("saveCalcResult <calcData>: ", calcData);

      if (calcData?.success) {
        this.calculator.total               = calcData?.data?.member?.financing_req_amount;
        this.member.financing_req_amount    = calcData?.data?.member?.financing_req_amount;
        this.member.financing_req_tenure    = calcData?.data?.member?.financing_req_tenure;
        this.member.financing_req_rate      = calcData?.data?.member?.financing_req_rate;
        this.member.financing_req_inst      = calcData?.data?.member?.financing_req_inst;
        this.member.financing_req_deds      = calcData?.data?.member?.financing_req_deds;
        this.member.financing_calc          = calcData?.data?.member?.financing_calc;
        this.employment.salary              = calcData?.data?.employment?.salary;
        this.employment.employer_type_id    = calcData?.data?.employment?.employer_type_id;
        this.application.request_amount     = calcData?.data?.request_amount;
        this.application.request_tenure     = calcData?.data?.request_tenure;
        this.application.request_rate       = calcData?.data?.request_rate;
        this.application.request_inst       = calcData?.data?.request_inst;
        this.application.request_deductions = calcData?.data?.request_deductions;
        this.application.request_calc       = calcData?.data?.request_calc;
        this.application.employment         = this.employment;
        this.application.member             = this.member;

        this.$store.commit(SET_MEMBER, this.member);
        this.$store.commit(SET_EMPLOYMENT, this.employment);
        this.$store.commit(SET_APPLICATION, this.application);
      }

      this.closeCalculator();

      //nfh-console.log("OUT: saveCalcResult");
    },

    openCalculator() {
      this.showCalculatorDialog = !this.isDisabled;
    },

    closeCalculator() {
      this.showCalculatorDialog = false;
    },

    loanAmountCleared() {
      //nfh-console.log("loanAmountCleared");
      // this.member.financing_req_amount = 0;
      // this.member.financing_req_tenure = 0;
      // this.member.financing_req_rate = 0;
      // this.member.financing_req_inst = 0;
      // this.application.request_amount = 0;
      // this.application.request_tenure = 0;
      // this.application.request_rate = 0;
      // this.application.request_inst = 0;
    },

    enableLoading() {
      this.flagLoading = true;
    },

    disableLoading() {
      this.flagLoading = false;
    },

    showSnackbarSuccess(message = "", title = "") {
      if (message != "") {
        this.snackbar.icon = "mdi-check-circle";
        this.snackbar.color = "success";
        this.snackbar.title = title;
        this.snackbar.text = message;
        this.snackbar.visible = true;
      }
    },

    showSnackbarFailure(message = "", title = "") {
      if (message != "") {
        this.snackbar.icon = "mdi-close-circle";
        this.snackbar.color = "red";
        this.snackbar.title = title;
        this.snackbar.text = message;
        this.snackbar.visible = true;
      }
    },

    showSnackbarReset() {
      this.snackbar.icon = "";
      this.snackbar.color = "";
      this.snackbar.title = "";
      this.snackbar.text = "";
      this.snackbar.visible = false;
    },

    save() {
      if (!this.isDisabled) {
        this.openOverlay();

        this.$refs.memberValidation.validate().then((success) => {
          if (success) {
            this.enableLoading();
            convUpperCase(this.member, this.exceptionList);

            setTimeout(() => {
              this.$store.dispatch(UPDATE_MEMBER, this.member)
                .then((resp) => {
                  if (resp.success) {
                    this.closeOverlay();
                    this.showSnackbarSuccess("Maklumat Diri telah disimpan.");
                    this.$store.dispatch(IMPORT_APPLICATION_TO_STORE, this.currentId);

                    this.$store.dispatch(UPDATE_APPLICATION_FINANCING, this.application).then((resp) => {
                      if (resp.data.success) {
                      }
                      else {
                      }
                    });
                  }
                  else {
                    this.closeOverlay();
                    this.showSnackbarFailure(resp.message);
                  }

                  this.closeOverlay();
                  this.disableLoading();
                })
                .catch((err) => {
                  // this.promptError(err);
                  this.closeOverlay();
                  this.showSnackbarFailure("Maklumat Diri gagal disimpan." + err);
                })
                .finally(() => {
                  this.disableLoading();
                  this.closeOverlay();
                });
            }, 200);
          }
          else {
            this.closeOverlay();
            this.showSnackbarFailure("Sila lengkapkan ruangan data wajib.", "Data Tidak Lengkap");
            this.goToError(this.$refs.memberValidation);
          }
        });
      }
    },

    goToError(_ref) {
      setTimeout(() => {
        const errors = Object.entries(_ref.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);

        _ref.refs[errors[0]["key"]]?.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    },

    promptError(message) {
      this.msgERR.message = errorMessages(message);
      this.showErrorMsgDialog = true;
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 200);
    },
  },

  async created() {
    //nfh-console.log(' IN:CREATED: ', this.currentId);

    this.city = JSON.parse(localStorage.getItem("cty"));
    this.races = JSON.parse(localStorage.getItem("rce"));
    this.banks = JSON.parse(localStorage.getItem("bnk"));
    this.genders = JSON.parse(localStorage.getItem("gdr"));
    this.salute = JSON.parse(localStorage.getItem("sal"));
    this.states = JSON.parse(localStorage.getItem("stt"));
    this.religions = JSON.parse(localStorage.getItem("rgn"));
    this.relations = JSON.parse(localStorage.getItem("rel"));
    this.employerTypes = JSON.parse(localStorage.getItem("emp"));
    this.maritalStatus = JSON.parse(localStorage.getItem("mry"));
    this.houseOwnership = JSON.parse(localStorage.getItem("own"));
    this.educationLevel = JSON.parse(localStorage.getItem("edu"));
    this.occupationTypes = JSON.parse(localStorage.getItem("occ"));
    this.cityHomeScoped = this.city.slice();
    this.cityMailingScoped = this.city.slice();

    if (this.currentId) {
      await this.$store.dispatch(IMPORT_APPLICATION_TO_STORE, this.currentId);
      //nfh-console.log('IMPORT_APPLICATION_TO_STORE this.application: ', this.application);

      await this.$store.dispatch(GET_MEMBER, this.currentId)
        .then(async (resp) => {
          //nfh-console.log('GET_MEMBER resp', resp);
          if (resp.data.success) {
            this.member = resp.data.data;
            //nfh-console.log('GET_MEMBER is SUCCESS');
          }
          else {
            this.member = blankMember();
            //nfh-console.log('GET_MEMBER is FAILED - FILL WITH BLANK');
          }
        })
        .catch((err) => {
          //nfh-console.log('GET_MEMBER is FAILED:\n', err);
        });
    }
    //nfh-console.log('this.member: ', this.member);
    //nfh-console.log('OUT:CREATED');
  },

  mounted() {
    //nfh-console.log(' IN:MOUNTED');
    this.closeOverlay();
    //nfh-console.log('OUT:MOUNTED');
  },

  async beforeCreate() {
    //nfh-console.log(" IN:BEFORECREATE");
    if (this.currentId) {
      await this.$store.dispatch(IMPORT_APPLICATION_TO_STORE, this.currentId);
      //nfh-console.log('IMPORT_APPLICATION_TO_STORE BEFORECREATE this.application: ', this.application);
    }
    //nfh-console.log("OUT:BEFORECREATE");
  },

  async beforeMounted() {
    //nfh-console.log(" IN:BEFOREMOUNTED");
    //nfh-console.log("OUT:BEFOREMOUNTED");
  },

  beforeDestroy() {
    //nfh-console.log(' IN:beforeDestroy');
    //nfh-console.log('OUT:beforeDestroy');
  },

}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.ucase-text input {
  text-transform: uppercase;
}

.v-text-field--outlined {
  font-size: 11pt;
}

.v-text-field label {
  font-size: 11pt;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 12pt;
}

.snackbar-title {
  font-size: 16px;
  font-weight: 600;
}

.snackbar-subtitle {
  font-size: 12px;
}

.input-large {
  font-size: large;
}
</style>