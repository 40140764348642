<template
>
  <div
  >
    <div
      class="card card-custom mb-10"
    >
      <div
        class="card-body p-0"
      >
        <b-card>
          <div
            class="d-flex align-self-center align-items-center"
          >
            <div
            >
              <h5
                class="primary--text font-weight-bold mb-0 mr-3"
              >
                {{
                  application == null ? "null" : application.application_type.name
                }}
              </h5>
            </div>
            <!--
            <v-chip
              v-if="currentUserRoles.includes(3) && (application ? application.is_completed : false)"
              close-icon="mdi-close-outline"
              color="green"
              filter
              label
              outlined
              pill
            >
              <v-icon left> mdi-check-circle </v-icon>
              Permohonan selesai</v-chip
            >
            -->
          </div>
          <v-divider
            class="mt-2"
          ></v-divider>
          <v-row>
            <v-col>
              <p
                class="font-weight-light"
              >
                Nombor Rujukan:
                <span
                  class="font-weight-bold"
                >
                  {{ application == null ? "" : application.ref_no }}
                </span>
                <br
                />
                Nama Pemohon:
                <span
                  class="font-weight-bold"
                >
                  {{ fullname }}
                </span>
                <br
                />
                No Kad Pengenalan:
                <span
                  class="font-weight-bold"
                >
                  {{ nric }}
                </span>
                <br
                />
                No. Telefon:
                <span
                  class="font-weight-bold"
                >
                  {{ phone }}
                </span>
                <br
                />
                E-mel:
                <span
                  class="font-weight-bold"
                >
                  {{ email }}
                </span>
              </p>
            </v-col>
            <v-col
            >
              <p
                class="font-weight-light"
              >
                Status:
                <span
                  class="font-weight-bold"
                >
                  {{ application == null ? "" : application.status.nameMS }}
                </span>
                <br
                />
                Tarikh hantar:
                <span
                  class="font-weight-bold"
                >
                  {{ application == null ? "" : application.submitted_date | formatDateTime }}
                </span>
              </p>
            </v-col>
          </v-row>

          <v-divider
            class="mt-0"
          ></v-divider>

          <!-- BEGIN Processing Only Buttons -->
          <span
            v-if="currentUserRoles.includes(2)"
          >
            <div
              class="pr-3 pl-3"
              style="display: flex; align-items: flex-end; flex-direction: column;"
            >
              <v-btn
                class="ml-4"
                color="primary"
                :loading="isLoadingProcessing"
                :disable="isExportable"
                @click="processing()"
              >
                <span
                  class="font-weight-bold"
                >
                  Hantar ke ILMS
                </span>
              </v-btn>
            </div>
          </span>
          <!-- END Processing Only Buttons -->

          <!-- BEGIN Sales Online Only Buttons -->
          <span
            v-if="currentUserRoles.includes(3)"
          >
            <div
              class="pr-3 pl-3 pt-3"
              style="display: flex; align-items: flex-end; flex-direction: column;"
            >
              <v-btn
                class="ml-4"
                color="primary"
                :loading="isLoadingSales"
                @click="completeApplication()"
              >
                <span
                  class="font-weight-bold"
                >
                  Hantar ke Processing
                </span>
              </v-btn>
            </div>
          </span>
          <!-- END Sales Online Only Buttons -->

        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

export default {
  name: "ApplicationInfo",

  components: {
  },

  props: {
  },

  data() {
    return {
      overlay: true,
      isLoadingSales: false,
      isLoadingProcessing: false,
    };
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
      "application",
      "member",
    ]),

    fullname() {
      return String(this.member?.full_name ?? "").toUpperCase().trim();
    },

    nric() {
      return String(this.member?.nric_display ?? (this.member?.nric ?? "")).toUpperCase().trim();
    },

    phone() {
      return String(this.member?.phone_num_display ?? (this.member?.phone_num ?? "")).toUpperCase().trim();
    },

    email() {
      return String(this.member?.email ?? "").toLowerCase().trim();
    },

    isExportable() {
      return false;
    },

  },

  methods: {
    completeApplication() {
      this.emit('salesCompleteAction');
    },

    processing() {
      this.emit('processingCompleteAction');
    },
  },

  mounted() {
    //nfh-console.log(' IN:MOUNTED');
    //nfh-console.log('OUT:MOUNTED');
  },
};
</script>

<style scoped>
</style>