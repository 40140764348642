<template>
  <div>
    <b-card-text>
      <div>
        <ValidationObserver ref="dsrFormValidation">
          <v-form class="font-weight-bold font-size-sm">
            <v-container fluid>
              <v-row>
                <v-col class="py-0" cols="12" lg="12" md="12">
                  <h1 class="primary--text font-weight-bold">
                    KADAR NISBAH KHIDMAT HUTANG (DSR) :
                    <span style="font-size: 16pt; color: black; white-spacing: nowrap;">
                      {{ d_s_r.dsr_rate | fPercentage }}
                    </span>
                  </h1><br />
                  <h6 class="primary--text font-weight-bold">
                    <i>
                      Formula DSR (%) = Jumlah Bayaran Balik Pembiayaan (A)
                    </i>
                  </h6>
                  <v-divider></v-divider>
                  <br /><br />
                </v-col>
                <v-col class="py-0" cols="12" lg="12" md="12">
                  <h5 class="primary--text font-weight-bold">
                    A. Jumlah Bayaran Balik Pembiayaan
                  </h5>
                  <!-- <v-divider class="mt-0"></v-divider> -->
                </v-col>
                <v-col class="py-0" cols="12" lg="6" md="6">
                  <h5 style="font-weight: 600;">i. Pembiayaan Dalam Slip Gaji</h5>
                  <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr class="text-center">
                          <th class="pl-0" scope="col">#</th>
                          <th class="pl-0" scope="col">Perkara</th>
                          <th class="pl-0" scope="col">RM</th>
                        </tr>
                      </thead>
                      <tbody class="table-borderless">
                        <tr>
                          <th class="text-center" scope="row">1</th>
                          <td>Pembiayaan Perumahan (1)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_ai1_house1" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">2</th>
                          <td>Pembiayaan Perumahan (2)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_ai2_house2" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">3</th>
                          <td>Pembiayaan Kenderaan (1)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_ai3_car1" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">4</th>
                          <td>Pembiayaan Kenderaan (2)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_ai4_car2" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">5</th>
                          <td>Pembiayaan Koperasi (1)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_ai5_kop1" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">6</th>
                          <td>Pembiayaan Koperasi (2)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_ai6_kop2" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">7</th>
                          <td>Lain-Lain (Dalam Slip Gaji)</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.ex_ai7a_name" class="ucase-text" hide-details="auto" outlined
                                dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_ai7a_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.ex_ai7b_name" class="ucase-text" hide-details="auto" outlined
                                dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_ai7b_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.ex_ai7c_name" class="ucase-text" hide-details="auto" outlined
                                dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_ai7c_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr style="text-align: center;">
                          <td colspan="2">
                            <h5 class="font-weight-bold">
                              Jumlah:
                            </h5>
                          </td>
                          <td>
                            <h5 class="font-weight-bold">
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_ai_total" v-bind:readonly="true" v-bind:disabled="false"
                                v-bind:outlined="false" v-bind:options="dsrOpt" v-bind:properties="moneyDSRProperties"
                                v-bind:valueWhenIsEmpty="moneyWhenEmpty" @input="dsrCalculate" />
                            </h5>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </v-col>

                <v-col class="py-0" cols="12" lg="6" md="6">
                  <h5 style="font-weight: 600;">ii. Pembiayaan Lain (Tiada dalam slip gaji)</h5>
                  <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr class="text-center">
                          <th class="pl-0" scope="col">#</th>
                          <th class="pl-0" scope="col">Perkara</th>
                          <th class="pl-0" scope="col">RM</th>
                        </tr>
                      </thead>
                      <tbody class="table-borderless">
                        <tr>
                          <th class="text-center" scope="row">1</th>
                          <td>Pembiayaan Perumahan (1)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_aii1_house1" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">2</th>
                          <td>Pembiayaan Perumahan (2)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_aii2_house2" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">3</th>
                          <td>Pembiayaan Kenderaan (1)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_aii3_car1" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">4</th>
                          <td>Pembiayaan Kenderaan (2)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_aii4_car2" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">5</th>
                          <td>Pembiayaan Koperasi (1)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_aii5_kop1" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">6</th>
                          <td>Pembiayaan Koperasi (2)</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_aii6_kop2" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">7</th>
                          <td>Lain-Lain (Dalam Slip Gaji)</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.ex_aii7a_name" class="ucase-text" hide-details="auto" outlined
                                dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_aii7a_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.ex_aii7b_name" class="ucase-text" hide-details="auto" outlined
                                dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_aii7b_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.ex_aii7c_name" class="ucase-text" hide-details="auto" outlined
                                dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_aii7c_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr style="text-align: center;">
                          <td colspan="2">
                            <h5 class="font-weight-bold">
                              Jumlah:
                            </h5>
                          </td>
                          <td>
                            <h5 class="font-weight-bold">
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.ex_aii_total" v-bind:readonly="true" v-bind:disabled="false"
                                v-bind:outlined="false" v-bind:options="dsrOpt" v-bind:properties="moneyDSRProperties"
                                v-bind:valueWhenIsEmpty="moneyWhenEmpty" @input="dsrCalculate" />
                            </h5>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </v-col>

                <v-col class="py-6" cols="12" lg="12" md="12">
                  <v-row class="py-2 px-4">
                    <v-col cols="12" lg="3" md="3" sm="3" xs="3">
                      <h5 style="font-weight: 600;">iii. Bayaran Balik Pembiayaan Baru</h5>
                    </v-col>

                    <v-col cols="12" lg="3" md="3" sm="3" xs="3">
                      <ValidationProvider>
                        <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                          v-model="d_s_r.ex_aiii_inst_new" v-bind:readonly="isMoneyReadOnly"
                          v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                          v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                          @input="dsrCalculate" />
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row class="py-2 px-4">
                    <v-col cols="12" lg="3" md="3" sm="3" xs="3">
                      <h5 style="font-weight: 600;">Jumlah Bayaran Balik Pembiayaan<br>(i + ii + iii)</h5>
                    </v-col>

                    <v-col cols="12" lg="3" md="3" sm="3" xs="3">
                      <ValidationProvider>
                        <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails" v-model="d_s_r.ex_a_total"
                          v-bind:readonly="true" v-bind:disabled="false" v-bind:outlined="false" v-bind:options="dsrOpt"
                          v-bind:properties="moneyDSRProperties" @input="dsrCalculate" />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="py-0" cols="12" lg="6" md="6">
                  <h5 class="primary--text font-weight-bold">
                    B. Pendapatan
                  </h5>
                  <!-- <v-divider class="mt-0"></v-divider> -->
                  <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr class="text-center">
                          <th class="pl-0" scope="col">#</th>
                          <th class="pl-0" scope="col">Perkara</th>
                          <th class="pl-0" scope="col">RM</th>
                        </tr>
                      </thead>
                      <tbody class="table-borderless">
                        <tr>
                          <th class="text-center" scope="row">1</th>
                          <td>Gaji Pokok</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b1_sal_basic" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">2</th>
                          <td>Elaun-Elaun Tetap</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b2a_allfixed_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b2a_allfixed_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b2b_allfixed_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b2b_allfixed_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b2c_allfixed_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b2c_allfixed_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b2d_allfixed_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b2d_allfixed_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b2e_allfixed_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b2e_allfixed_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">3</th>
                          <td>Lain-Lain Elaun</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b3a_allother_name" hint="Elaun Sara Hidup"
                                class="ucase-text" hide-details="auto" outlined dense required
                                :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b3a_allother_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b3b_allother_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b3b_allother_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b3c_allother_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b3c_allother_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b3d_allother_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b3d_allother_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b3e_allother_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b3e_allother_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">4</th>
                          <td>Pendapatan Tambahan</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b4a_income_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b4a_income_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b4b_income_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b4b_income_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b4c_income_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b4c_income_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b4d_income_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b4d_income_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_b4e_income_name" class="ucase-text" hide-details="auto"
                                outlined dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b4e_income_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr style="text-align: center;">
                          <td colspan="2">
                            <h5 class="font-weight-bold">
                              Jumlah:
                            </h5>
                          </td>
                          <td>
                            <h5 class="font-weight-bold">
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_b_total" v-bind:readonly="true" v-bind:disabled="false"
                                v-bind:outlined="false" v-bind:options="dsrOpt" v-bind:properties="moneyDSRProperties"
                                v-bind:valueWhenIsEmpty="moneyWhenEmpty" @input="dsrCalculate" />
                            </h5>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </v-col>

                <v-col class="py-0" cols="12" lg="6" md="6">
                  <h5 class="primary--text font-weight-bold">
                    C. Amaun Statutori
                  </h5>
                  <!-- <v-divider class="mt-0"></v-divider> -->
                  <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead class="thead-dark">
                        <tr style="text-align: center;">
                          <th class="pl-0" scope="col">#</th>
                          <th class="pl-0" scope="col">Perkara</th>
                          <th class="pl-0" scope="col">RM</th>
                        </tr>
                      </thead>
                      <tbody class="table-borderless">
                        <tr>
                          <th class="text-center" scope="row">1</th>
                          <td>KWSP</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_c1_epf" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">2</th>
                          <td>PERKESO</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_c2_socso" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">3</th>
                          <td>Cukai Pendapatan</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_c3_pcb" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">4</th>
                          <td>Zakat Pendapatan</td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_c4_zakat" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row">5</th>
                          <td>Lain-Lain (Sila nyatakan)</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_c5a_name" class="ucase-text" hide-details="auto" outlined
                                dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_c5a_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_c5b_name" class="ucase-text" hide-details="auto" outlined
                                dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_c5b_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" scope="row"></th>
                          <td>
                            <ValidationProvider>
                              <v-text-field v-model="d_s_r.in_c5c_name" class="ucase-text" hide-details="auto" outlined
                                dense required :readonly="isDisabled"></v-text-field>
                            </ValidationProvider>
                          </td>
                          <td>
                            <ValidationProvider>
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails"
                                v-model="d_s_r.in_c5c_amt" v-bind:readonly="isMoneyReadOnly"
                                v-bind:disabled="moneyDisabled" v-bind:outlined="moneyOutlined" v-bind:options="dsrOpt"
                                v-bind:properties="moneyDSRProperties" v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                                @input="dsrCalculate" />
                            </ValidationProvider>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr style="text-align: center;">
                          <td colspan="2">
                            <h5 class="font-weight-bold">
                              Jumlah:
                            </h5>
                          </td>
                          <td>
                            <h5 class="font-weight-bold">
                              <vuetify-money :dense="moneyDense" :hide-details="moneyHideDetails" :filled="true"
                                v-model="d_s_r.in_c_total" v-bind:readonly="true" v-bind:disabled="false"
                                v-bind:outlined="false" v-bind:options="dsrOpt" v-bind:properties="moneyDSRProperties"
                                v-bind:valueWhenIsEmpty="moneyWhenEmpty" @input="dsrCalculate" />
                            </h5>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
      </div>
    </b-card-text>

    <div class="pr-3 pl-3 pb-2 pt-10" style="display: flex; align-items: flex-end; flex-direction: column;">
      <v-btn v-if="!isDisabled" class="ml-4" color="primary" :loading="isLoadingDSR" @click="save()">
        <span class="font-weight-bold">Simpan</span>
      </v-btn>
    </div>

    <ErrorMsgDialog v-model="openErrorMsgDialog" title="Ralat" :content="msgERR.message" :showProceedButton="true"
      :showBackButton="false" :delayOnProceed="false" :isTypeError="true" proceedButton="OK"></ErrorMsgDialog>

    <InfoMsgDialog v-model="openInfoMsgDialog" title="Info" :content="msgINFO.message" :showProceedButton="true"
      :showBackButton="false" :delayOnProceed="false" proceedButton="OK"></InfoMsgDialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  SET_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module";

import {
  CREATE_DSR,
  GET_DSR,
} from "@/core/services/store/dsr.module";

import {
  required,
  max,
  numeric,
} from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import {
  blankDSR,
  calcDSR,
} from "@/core/services/helper.service";

// import ErrorMsgDialog from "@/view/pages/widgets/MessageDialog.vue";
// import InfoMsgDialog from "@/view/pages/widgets/MessageDialog.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});


export default {
  name: "DSRDetails",

  components: {
    ValidationObserver,
    ValidationProvider,
    // ErrorMsgDialog,
    // InfoMsgDialog,
  },

  props: {
    propReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      overlay: true,
      moneyDense: true,
      moneyHideDetails: "auto",
      moneyProperties: {
        error: true,
        "hide-details": "auto",
      },

      moneyPropertiesRequired: {
        error: true,
        "hide-details": "auto",
        rules: "required",
      },

      moneyDSRProperties: {
        error: false,
        "hide-details": "auto",
      },

      moneyDSRCalcProperties: {
        error: false,
        "hide-details": "auto",
        filled: true,
      },

      moneyReadOnly: false,
      moneyDisabled: false,
      moneyOutlined: true,
      moneyClearable: true,
      moneyNumEmpty: "",
      moneyWhenEmpty: "0.00",

      dsrOpt: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 2,
        min: 0,
        max: 1000000,
      },

      theData: blankDSR(),
      flagLoading: false,
      openErrorMsgDialog: false,
      openInfoMsgDialog: false,

      snackbar: {
        icon: "",
        color: "",
        title: "",
        text: "",
        visible: false,
      },

      msgERR: {
        message: "",
        nric: "",
        run: 0,
      },

      msgINFO: {
        message: "",
        nric: "",
        run: 0,
      },

      alignLeft: "left",
    }
  },

  watch: {
  },

  computed: {
    ...mapGetters([
      "application",
      "currentUser",
      "currentUserRoles",
    ]),

    d_s_r: {
      get: function () {
        return this.theData;
      },

      set: function (val) {
        this.theData = val;
        //nfh-console.log('COMPUTED val, this.theData: ', val, this.theData);
      }
    },

    currentId() {
      return parseInt(this.$route.params.id) ?? 0;
    },

    isMoneyClearable() {
      return !!(this.isDisabled ? false : true);
    },

    isMoneyDisabled() {
      return !!this.isDisabled;
    },

    isMoneyReadOnly() {
      return !!this.isDisabled;
    },

    isDisabled() {
      return !!this.propReadOnly;
    },

    isLoadingDSR() {
      return !!this.flagLoading;
    }
  },

  methods: {
    async onValidate() {
      return new Promise((resolve, reject) => {
        this.$refs.dsrValidation.validate()
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
      });
    },

    dsrCalculate(e) {
      this.d_s_r = calcDSR(this.d_s_r);
    },

    enableDSRLoading() {
      this.flagLoading = true;
    },

    disableDSRLoading() {
      this.flagLoading = false;
    },

    showSnackbarSuccess(message = "", title = "") {
      if (message != "") {
        this.snackbar.icon = "mdi-check-circle";
        this.snackbar.color = "success";
        this.snackbar.title = title;
        this.snackbar.text = message;
        this.snackbar.visible = true;
      }
    },

    showSnackbarFailure(message = "", title = "") {
      if (message != "") {
        this.snackbar.icon = "mdi-close-circle";
        this.snackbar.color = "red";
        this.snackbar.title = title;
        this.snackbar.text = message;
        this.snackbar.visible = true;
      }
    },

    showSnackbarReset() {
      this.snackbar.icon = "";
      this.snackbar.color = "";
      this.snackbar.title = "";
      this.snackbar.text = "";
      this.snackbar.visible = false;
    },

    save() {
      if (!this.isDisabled) {
        this.openOverlay();

        this.$refs.dsrFormValidation.validate()
          .then((success) => {
            if (success) {
              this.enableDSRLoading();
              setTimeout(async () => {
                await this.$store
                  .dispatch(CREATE_DSR, Object.assign({ application_id: this.currentId }, this.d_s_r))
                  .then((resp) => {
                    if (resp.data.success == true) {
                      this.d_s_r = resp.data.data;
                      this.closeOverlay();
                      this.showSnackbarSuccess("Maklumat DSR telah disimpan.");
                    }
                    else {
                      this.d_s_r = resp.data.data;
                      this.closeOverlay();
                      this.showSnackbarFailure("Maklumat DSR tidak berjaya disimpan.");
                    }
                    this.closeOverlay();
                    this.disableDSRLoading();
                  })
                  .catch((err) => {
                    this.disableDSRLoading();
                    this.closeOverlay();
                  })
                  .finally(() => {
                    this.disableDSRLoading();
                    this.closeOverlay();
                  });
              });
            }
            else {
              this.closeOverlay();
              this.showSnackbarFailure("Sila lengkapkan ruangan data wajib.", "Data Tidak Lengkap");
              this.goToError(this.$refs.dsrFormValidation);
            }
          });
      }
    },

    goToError(_ref) {
      setTimeout(() => {
        const errors = Object.entries(_ref.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);

        _ref.refs[errors[0]["key"]]?.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 200);
    },
  },

  async created() {
    //nfh-console.log(' IN:CREATED: ', this.currentId);
    if (this.currentId) {
      await this.$store.dispatch(GET_DSR, this.currentId)
        .then(async (resp) => {
          //nfh-console.log('GET_DSR resp', resp);
          if (resp.data.success) {
            this.d_s_r = resp.data.data;
            //nfh-console.log('GET_DSR is SUCCESS');
          }
          else {
            this.d_s_r = blankDSR();
            //nfh-console.log('GET_DSR is FAILED - FILL WITH BLANK');
          }
        })
        .catch((err) => {
          //nfh-console.log('GET_DSR is FAILED:\n', err);
        });
    }
    //nfh-console.log('this.d_s_r: ', this.d_s_r);
    //nfh-console.log('OUT:CREATED');
  },

  mounted() {
    //nfh-console.log(' IN:MOUNTED');
    this.closeOverlay();
    //nfh-console.log('OUT:MOUNTED');
  },

  beforeDestroy() {
    //nfh-console.log(' IN:beforeDestroy');
    //nfh-console.log('OUT:beforeDestroy');
  },

}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.ucase-text input {
  text-transform: uppercase;
}

.v-text-field--outlined {
  font-size: 10pt;
}

.v-text-field label {
  font-size: 10pt;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 10pt;
}
</style>