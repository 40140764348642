var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: _vm.alignCenter } },
    [
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbar.color, top: "" },
          model: {
            value: _vm.snackbar.visible,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "visible", $$v)
            },
            expression: "snackbar.visible",
          },
        },
        [
          _c(
            "v-row",
            {
              attrs: {
                "no-gutters": "",
                align: _vm.alignCenter,
                justify: _vm.alignCenter,
              },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [_c("v-icon", [_vm._v(" " + _vm._s(_vm.snackbar.icon) + " ")])],
                1
              ),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("div", { staticClass: "mx-3" }, [
                  _vm._v(" " + _vm._s(_vm.snackbar.title) + " "),
                  _c("p", { staticClass: "snackbar-subtitle mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
                  ]),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.snackbar.visible = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
        [
          _c("ApplicationInfo", {
            on: {
              salesCompleteAction: _vm.completeApplication,
              processingCompleteAction: _vm.processing,
            },
          }),
          _c("div", { staticClass: "card card-custom" }, [
            _c(
              "div",
              { staticClass: "card-body p-0" },
              [
                _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c(
                      "b-tabs",
                      {
                        attrs: { pills: "", card: "" },
                        model: {
                          value: _vm.tIndex,
                          callback: function ($$v) {
                            _vm.tIndex = $$v
                          },
                          expression: "tIndex",
                        },
                      },
                      [
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              "title-link-class": "font-weight-bold",
                              title: "Maklumat Diri",
                            },
                          },
                          [
                            _c("MemberDetails", {
                              ref: "memberDetails",
                              attrs: {
                                propData: _vm.application,
                                propReadOnly: _vm.isDisabled,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              "title-link-class": "font-weight-bold",
                              title: "Maklumat Pekerjaan",
                            },
                          },
                          [
                            _c("EmploymentDetails", {
                              ref: "employmentDetails",
                              attrs: { propReadOnly: _vm.isDisabled },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              "title-link-class": "font-weight-bold",
                              title: "Maklumat Waris",
                            },
                          },
                          [
                            _c("HeirDetails", {
                              ref: "heirDetails",
                              attrs: { propReadOnly: _vm.isDisabled },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-tab",
                          { attrs: { "title-link-class": "font-weight-bold" } },
                          [
                            _c(
                              "template",
                              { slot: "title" },
                              [
                                _vm._v(" Dokumen Pengesahan "),
                                (
                                  this.application == null
                                    ? 0
                                    : this.application.checklist == 0
                                )
                                  ? _c("v-badge", {
                                      staticClass: "ml-2 mx-1",
                                      attrs: { color: "pink", dot: "" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-card-text",
                              [
                                _c("DocumentForm", {
                                  attrs: {
                                    propId: _vm.currentId,
                                    propForm: _vm.application,
                                    propRoles: _vm.currentUserRoles,
                                    propDocList: _vm.checklistAppFull,
                                    propReadOnly: _vm.isDisabled,
                                    propUseForm: !true,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _vm.currentUserRoles.includes(2) ||
                        _vm.currentUserRoles.includes(3)
                          ? _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: "DSR",
                                  "title-link-class": "font-weight-bold",
                                },
                              },
                              [
                                _c("DSRDetails", {
                                  attrs: { propReadOnly: _vm.isDisabled },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("ErrorMsgDialog", {
            attrs: {
              title: "Ralat",
              content: _vm.msgERR.message,
              showProceedButton: true,
              showBackButton: false,
              delayOnProceed: false,
              isTypeError: true,
              modal: true,
              proceedButton: "OK",
            },
            model: {
              value: _vm.openErrorMsgDialog,
              callback: function ($$v) {
                _vm.openErrorMsgDialog = $$v
              },
              expression: "openErrorMsgDialog",
            },
          }),
          _c("InfoMsgDialog", {
            attrs: {
              title: "Info",
              content: _vm.msgINFO.message,
              showProceedButton: true,
              showBackButton: false,
              delayOnProceed: false,
              modal: true,
              proceedButton: "OK",
            },
            model: {
              value: _vm.openInfoMsgDialog,
              callback: function ($$v) {
                _vm.openInfoMsgDialog = $$v
              },
              expression: "openInfoMsgDialog",
            },
          }),
          _c(
            "v-overlay",
            { attrs: { value: _vm.overlay } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }