var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card card-custom mb-10" }, [
      _c(
        "div",
        { staticClass: "card-body p-0" },
        [
          _c(
            "b-card",
            [
              _c(
                "div",
                { staticClass: "d-flex align-self-center align-items-center" },
                [
                  _c("div", [
                    _c(
                      "h5",
                      {
                        staticClass: "primary--text font-weight-bold mb-0 mr-3",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.application == null
                                ? "null"
                                : _vm.application.application_type.name
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _c("v-divider", { staticClass: "mt-2" }),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("p", { staticClass: "font-weight-light" }, [
                      _vm._v(" Nombor Rujukan: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.application == null
                                ? ""
                                : _vm.application.ref_no
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _vm._v(" Nama Pemohon: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(_vm.fullname) + " "),
                      ]),
                      _c("br"),
                      _vm._v(" No Kad Pengenalan: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(_vm.nric) + " "),
                      ]),
                      _c("br"),
                      _vm._v(" No. Telefon: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(_vm.phone) + " "),
                      ]),
                      _c("br"),
                      _vm._v(" E-mel: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(_vm.email) + " "),
                      ]),
                    ]),
                  ]),
                  _c("v-col", [
                    _c("p", { staticClass: "font-weight-light" }, [
                      _vm._v(" Status: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.application == null
                                ? ""
                                : _vm.application.status.nameMS
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _vm._v(" Tarikh hantar: "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDateTime")(
                                _vm.application == null
                                  ? ""
                                  : _vm.application.submitted_date
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mt-0" }),
              _vm.currentUserRoles.includes(2)
                ? _c("span", [
                    _c(
                      "div",
                      {
                        staticClass: "pr-3 pl-3",
                        staticStyle: {
                          display: "flex",
                          "align-items": "flex-end",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-4",
                            attrs: {
                              color: "primary",
                              loading: _vm.isLoadingProcessing,
                              disable: _vm.isExportable,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.processing()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(" Hantar ke ILMS "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.currentUserRoles.includes(3)
                ? _c("span", [
                    _c(
                      "div",
                      {
                        staticClass: "pr-3 pl-3 pt-3",
                        staticStyle: {
                          display: "flex",
                          "align-items": "flex-end",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-4",
                            attrs: {
                              color: "primary",
                              loading: _vm.isLoadingSales,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.completeApplication()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(" Hantar ke Processing "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }