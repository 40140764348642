<template>
  <div>
    <!-- <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="1">
          <v-icon>{{ snackbar.icon }}</v-icon>
        </v-col>
        <v-col cols="10">
          <div class="mx-3">
            <p class="snackbar-title font-weight-bold mb-0">
              {{ snackbar.title }}
            </p>
            <p class="snackbar-subtitle mb-0">
              {{ snackbar.text }}
            </p>
          </div>
        </v-col>
        <v-col cols="1">
          <v-btn icon @click="snackbar.visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar> -->

    <b-card-text>
      <div>
        <ValidationObserver
          ref="employmentValidation"
        >
          <v-form
            class="font-weight-bold font-size-sm"
          >
            <v-container
              fluid
            >
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <h5
                    class="primary--text font-weight-bold"
                  >
                    Maklumat Pekerjaan
                  </h5>
                  <v-divider
                    class="mt-0"
                  ></v-divider>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  md="8"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nama Majikan"
                    rules="required"
                  >
                    <v-text-field
                      v-model="emp.employer_name"
                      label="Nama Majikan"
                      maxLength="128"
                      class="ucase-text"
                      :readonly="isDisabled"
                      :error-messages="errors"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Kategori Majikan"
                    rules="required"
                  >
                    <v-select
                      v-model="emp.employer_type_id"
                      label="Kategori Majikan"
                      item-text="name"
                      item-value="id"
                      :items="employerTypes"
                      :error-messages="errors"
                      :readonly="isDisabled"
                      required="18"
                      outlined
                      dense
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Pekerjaan"
                    rules="required"
                  >
                    <v-text-field
                      v-model="emp.occupation"
                      label="Pekerjaan"
                      maxLength="120"
                      :error-messages="errors"
                      :readonly="isDisabled"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Kategori Pekerjaan"
                    rules="required"
                  >
                    <v-select
                      v-model="emp.occupation_type_id"
                      label="Kategori Pekerjaan"
                      item-text="nameMS"
                      item-value="id"
                      :items="occupationTypes"
                      :error-messages="errors"
                      :readonly="isDisabled"
                      required="18"
                      outlined
                      dense
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Bahagian/ Unit"
                    rules="required"
                  >
                    <v-text-field
                      v-model="emp.department"
                      :error-messages="errors"
                      :readonly="isDisabled"
                      label="Bahagian/ Unit"
                      maxLength="120"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Pendapatan Sebulan"
                    rules="required"
                  >
                    <vuetify-money
                      v-model="emp.salary"
                      :dense="moneyDense"
                      :error-messages="errors"
                      v-bind:label="salaryLabel"
                      v-bind:valueWhenIsEmpty="moneyNumEmpty"
                      v-bind:placeholder="salaryPlaceholder"
                      v-bind:properties="moneySalaryProperties"
                      v-bind:clearable="false"
                      v-bind:readonly="true"
                      v-bind:disabled="moneyDisabled"
                      v-bind:outlined="moneyOutlined"
                      v-bind:options="salaryOpt"
                    />
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Tarikh Mula Bekerja"
                    rules="required"
                  >
                    <v-menu
                      v-model="dateOfServiceMenu"
                      :error-messages="errors"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-text-field
                          :value="computedDateOfService"
                          label="Tarikh Mula Bekerja"
                          v-mask="mask_date"
                          v-bind="attrs"
                          v-on="on"
                          :readonly="isDisabled"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="emp.commencement_date"
                        v-on:change="onCommenceDateChange"
                        locale="ms-my"
                        :max="maxDateEligibleWork"
                        :readonly="isDisabled"
                        :disabled="isDisabled"
                        @input="dateOfServiceMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Tahun Perkhidmatan"
                    rules="required"
                  >
                    <v-text-field
                      v-model="emp.year_of_service"
                      :error-messages="errors"
                      label="Tahun Perkhidmatan"
                      hint="Dikira berdasarkan dari tarikh mula bekerja"
                      required
                      readonly
                      outlined
                      filled
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <h5
                    class="primary--text font-weight-bold"
                  >
                    Alamat Syarikat
                  </h5>
                  <v-divider
                    class="mt-0"
                  ></v-divider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Unit/Lot/No./Tingkat"
                    rules="required"
                  >
                    <v-text-field
                      v-model="emp.office_unit"
                      label="Unit/Lot/No./Tingkat"
                      hint="Contoh A1-2-3"
                      maxLength="255"
                      :readonly="isDisabled"
                      :error-messages="errors"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Bangunan/Jalan"
                    rules="required"
                  >
                    <v-text-field
                      v-model="emp.office_street"
                      label="Bangunan/Jalan"
                      hint="Wisma Persekutuan, Jalan Damansara"
                      maxLength="255"
                      :readonly="isDisabled"
                      :error-messages="errors"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Butiran Tambahan 1"
                    rules=""
                  >
                    <v-text-field
                      v-model="emp.office_line3"
                      label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                      hint="Masukkan butiran alamat sekiranya ada"
                      maxLength="255"
                      class="ucase-text"
                      :error-messages="errors"
                      :readonly="isDisabled"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Butiran Tambahan 2"
                    rules=""
                  >
                    <v-text-field
                      v-model="emp.office_line4"
                      hint="Masukkan butiran alamat sekiranya ada"
                      :error-messages="errors"
                      :readonly="isDisabled"
                      label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                      class="ucase-text"
                      maxLength="255"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Poskod"
                    rules="required"
                  >
                    <v-text-field
                      v-model="emp.office_postcode"
                      label="Poskod"
                      v-mask="mask_postCode"
                      maxLength="5"
                      :readonly="isDisabled"
                      :error-messages="errors"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="8">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Bandar"
                    rules="required"
                  >
                    <v-select
                      v-model="emp.office_city_id"
                      v-on:change="onOfficeCityChange"
                      label="Bandar"
                      hint="Bandar mengikut poskod"
                      item-text="display"
                      item-value="id"
                      :items="city"
                      :readonly="isDisabled"
                      :error-messages="errors"
                      required
                      outlined
                      dense
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="8"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Negeri"
                    rules="required"
                  >
                    <v-select
                      v-model="emp.office_state_id"
                      label="Negeri"
                      item-text="name"
                      item-value="id"
                      :items="states"
                      :readonly="isDisabled"
                      :error-messages="errors"
                      required
                      outlined
                      dense
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <h5
                    class="primary--text font-weight-bold"
                  >
                    Telefon
                  </h5>
                  <v-divider
                    class="mt-0"
                  ></v-divider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="5"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Telefon Pejabat"
                    rules="required"
                  >
                    <vue-tel-input-vuetify
                      v-model="emp.office_num"
                      v-mask="mask_phoneLand"
                      label="No. Telefon Pejabat"
                      hint="Contoh: 03-1234 5678 / 04-123 4567"
                      placeholder="No.Telefon Pejabat"
                      defaultCountry="MY"
                      :onlyCountries="['MY']"
                      :readonly="isDisabled"
                      :error-messages="errors"
                      :background-color="inputColorOfficeNum"
                      @input="onOfficeNumInput"
                      required
                      outlined
                      dense
                    ></vue-tel-input-vuetify>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="2"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Sambungan"
                    rules=""
                  >
                    <v-text-field
                      v-model="emp.office_num_ext"
                      v-mask="mask_postCode"
                      :error-messages="errors"
                      :readonly="isDisabled"
                      label="Sambungan"
                      maxLength="6"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="5"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Fax Pejabat"
                    rules=""
                  >
                    <vue-tel-input-vuetify
                      v-model="emp.office_fax"
                      v-mask="mask_phoneLand"
                      hint="Contoh: 03-1234 5678 / 04-123 4567"
                      label="No. Faks"
                      placeholder="No. Faks"
                      defaultCountry="MY"
                      :onlyCountries="['MY']"
                      :readonly="isDisabled"
                      :error-messages="errors"
                      :background-color="inputColorOfficeFax"
                      @input="onOfficeFaxInput"
                      outlined
                      dense
                      ></vue-tel-input-vuetify>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="8">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="E-Mel Majikan"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="emp.employer_email"
                      label="E-Mel Majikan :"
                      hint="Contoh: jabatan@organisasi.gov.my"
                      placeholder="jabatan@organisasi.gov.my"
                      :error-messages="errors"
                      outlined
                      dense
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
      </div>
    </b-card-text>

    <div class="pr-3 pl-3 pb-2 pt-10" style="display: flex; align-items: flex-end; flex-direction: column;">
      <v-btn v-if="!isDisabled" class="ml-4" color="primary" :loading="flagLoading" @click="save()">
        <span class="font-weight-bold">Simpan</span>
      </v-btn>
    </div>

    <ErrorMsgDialog v-model="openErrorMsgDialog" title="Ralat" :content="msgERR.message" :showProceedButton="true"
      :showBackButton="false" :delayOnProceed="false" :isTypeError="true" :proceedButton="'OK'"></ErrorMsgDialog>

    <InfoMsgDialog v-model="openInfoMsgDialog" title="Info" :content="msgINFO.message" :showProceedButton="true"
      :showBackButton="false" :delayOnProceed="false" :proceedButton="'OK'"></InfoMsgDialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" elevation="24" top>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="1">
          <v-icon>{{ snackbar.icon }}</v-icon>
        </v-col>
        <v-col cols="10">
          <div class="mx-3">
            {{ snackbar.title }}
            <p class="mb-0">
              {{ snackbar.text }}
            </p>
          </div>
        </v-col>
        <v-col cols="1">
          <v-btn icon @click="snackbar.visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>

</div>
</template>

<script>
import dayjs from "dayjs";

import {
  mapGetters,
} from "vuex";

import {
  SET_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module";

// import {
// } from "@/core/services/store/form.module";

import {
  GET_EMPLOYMENT,
  CREATE_EMPLOYMENT,
  UPDATE_EMPLOYMENT,
} from "@/core/services/store/application.module";

import {
  required,
  max,
  numeric,
} from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import {
  blankEmployment,
  errorMessages,
} from "@/core/services/helper.service";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});


export default {
  name: "EmploymentDetails",

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    propReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      city: [],
      states: [],
      employerTypes: [],
      occupationTypes: [],
      cityOfficeScoped: [],

      localEmployment: blankEmployment(),

      mask_date: "##/##/####",
      mask_postCode: "#####",
      mask_phoneLand: "##-########",
      mask_phoneMobile: "###-########",
      mask_yearServing: "##",

      overlay: true,
      moneyDense: true,
      dateOfServiceMenu: false,

      phone: {
        office_num: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },

        office_fax: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },
      },

      moneyProperties: {
        error: true,
        "hide-details": "auto",
      },

      moneySalaryProperties: {
        error: true,
        backgroundColor: "gray",
        "hide-details": "auto",
        hint: "Diambil dari input kalkulator",
      },

      moneyPropertiesRequired: {
        error: true,
        "hide-details": "auto",
        rules: "required",
      },

      moneyDSRProperties: {
        error: false,
        "hide-details": "auto",
      },

      moneyDSRCalcProperties: {
        error: false,
        "hide-details": "auto",
        filled: true,
      },

      moneyReadOnly: false,
      moneyDisabled: false,
      moneyOutlined: true,
      moneyClearable: true,
      moneyHideDetails: "auto",
      moneyNumEmpty: "",
      moneyWhenEmpty: "0.00",

      salaryLabel: "Pendapatan Sebulan",
      salaryPlaceholder: "Gaji Kasar",
      salaryHint: "Masukkan Nilai Gaji Kasar Sebelum Penolakan",

      salaryOpt: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 2,
        min: 500,
        max: 1000000,
      },

      theData: blankEmployment(),
      flagLoading: false,
      openErrorMsgDialog: false,
      openInfoMsgDialog: false,

      snackbar: {
        icon: "",
        color: "",
        title: "",
        text: "",
        visible: false,
      },

      msgERR: {
        message: "",
        nric: "",
        run: 0,
      },

      msgINFO: {
        message: "",
        nric: "",
        run: 0,
      },

      inputColorOK: "cyan lighten-5",
      inputColorNG: "red lighten-4",
    }
  },

  watch: {
    application: function (newValue, oldValue) {
      //nfh-console.log("WATCH: application changed", oldValue, newValue)
      // this.signature = newValue;
    },

    employment: function (newValue, oldValue) {
      //nfh-console.log("WATCH: application changed", oldValue, newValue)
      this.theData = newValue;
    },
  },

  computed: {
    ...mapGetters([
      "application",
      "employment",
      "currentUser",
      "currentUserRoles",
    ]),

    emp: {
      get: function () {
        return this.theData;
      },

      set: function (val) {
        this.theData = val;
        //nfh-console.log('COMPUTED val, this.theData: ', val, this.theData);
      }
    },

    currentId() {
      return parseInt(this.$route.params.id) ?? 0;
    },

    computedDateOfService() {
      return this.emp.commencement_date
        ? dayjs(this.emp.commencement_date).format("DD/MM/YYYY")
        : "";
    },

    inputColorOfficeNum() {
      return this.phone.office_num.color;
    },

    inputColorOfficeFax() {
      return this.phone.office_fax.color;
    },

    maxDateEligibleWork() {
      return dayjs().subtract(2, "month").endOf("month").format("YYYY-MM-DD");
    },

    isMoneyClearable() {
      return !!(this.isDisabled ? false : true);
    },

    isMoneyDisabled() {
      return !!this.isDisabled;
    },

    isMoneyReadOnly() {
      return !!this.isDisabled;
    },

    isDisabled() {
      return !!this.propReadOnly;
    },

    isLoadingDSR() {
      return !!this.flagLoading;
    }
  },

  methods: {
    async init() {
      //nfh-console.log(' IN: INIT');
      this.onCommenceDateChange();
      //nfh-console.log('OUT: INIT');
    },

    async onValidate() {
      return new Promise((resolve, reject) => {
        this.$refs.employmentValidation.validate()
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
      });
    },

    enableDSRLoading() {
      this.flagLoading = true;
    },

    disableDSRLoading() {
      this.flagLoading = false;
    },

    onOfficeCityChange(value) {
      if (
        this.city.find((ct) => {
          if (ct.id == value) {
            this.emp.office_state_id = ct.state_id;
          }
        })
      );
    },

    onOfficeStateChange(value) {
      this.cityOfficeScoped = value
        ? this.city.slice().filter((ct) => ct.state_id === value)
        : this.city.slice();
    },

    onOfficeNumInput(formattedNumber, { number, valid, country }) {
      this.phone.office_num.country = country;
      this.phone.office_num.number = number.national;
      this.phone.office_num.mobile = parseInt(this.phone.office_num?.number.split('-')[0]) > 9;
      this.phone.office_num.valid = valid && !this.phone.office_num?.mobile;
      this.phone.office_num.color = (this.phone.office_num.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onOfficeFaxInput(formattedNumber, { number, valid, country }) {
      this.phone.office_fax.country = country;
      this.phone.office_fax.number = number.national;
      this.phone.office_fax.mobile = parseInt(this.phone.office_fax.number.split('-')[0]) > 9;
      this.phone.office_fax.valid = valid && !this.phone.office_fax.mobile;
      this.phone.office_fax.color = (this.phone.office_fax.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onCommenceDateChange() {
      this.emp.year_of_service = (dayjs().diff(dayjs(this.emp.commencement_date), "months") / 12).toFixed(1);
      //nfh-console.log('onCommenceDateChange:', this.emp.year_of_service);
    },

    showSnackbarSuccess(message = "", title = "") {
      if (message != "") {
        this.snackbar.icon = "mdi-check-circle";
        this.snackbar.color = "success";
        this.snackbar.title = title;
        this.snackbar.text = message;
        this.snackbar.visible = true;
      }
    },

    showSnackbarFailure(message = "", title = "") {
      if (message != "") {
        this.snackbar.icon = "mdi-close-circle";
        this.snackbar.color = "red";
        this.snackbar.title = title;
        this.snackbar.text = message;
        this.snackbar.visible = true;
      }
    },

    showSnackbarReset() {
      this.snackbar.icon = "";
      this.snackbar.color = "";
      this.snackbar.title = "";
      this.snackbar.text = "";
      this.snackbar.visible = false;
    },

    save() {
      if (!this.isDisabled) {
        this.openOverlay();

        this.$refs.employmentValidation.validate()
          .then((success) => {
            if (success) {
              this.flagLoading = true;
              setTimeout(() => {
                this.$store.dispatch(UPDATE_EMPLOYMENT, this.emp)
                  .then((resp) => {
                    //nfh-console.log("[EmploymentDetails] save(): then: ", resp);
                    if (resp.success) {
                      this.closeOverlay();
                      this.showSnackbarSuccess("Maklumat Pekerjaan telah disimpan.");
                    }
                    else {
                      this.closeOverlay();
                      this.showSnackbarFailure(resp.message);
                    }

                    this.closeOverlay();
                    this.flagLoading = false;
                  })
                  .catch((err) => {
                    //nfh-console.log("[EmploymentDetails] save(): Catch Error: ", err);
                    this.closeOverlay();
                    this.promptError(err);
                  })
                  .finally(() => {
                    this.flagLoading = false;
                    this.closeOverlay();
                  });
              }, 200);
            }
            else {
              this.closeOverlay();
              this.showSnackbarFailure("Sila lengkapkan ruangan data wajib.", "Data Tidak Lengkap");
              this.goToError(this.$refs.employmentValidation);
            }
          });
      }
    },

    promptError(message) {
      this.msgERR.message = errorMessages(message);
      this.openErrorMsgDialog = true;
    },

    goToError(_ref) {
      setTimeout(() => {
        const errors = Object.entries(_ref.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);

        _ref.refs[errors[0]["key"]]?.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 200);
    },
  },

  async created() {
    //nfh-console.log(' IN:CREATED: ', this.currentId);

    this.city = JSON.parse(localStorage.getItem("cty"));
    this.states = JSON.parse(localStorage.getItem("stt"));
    this.employerTypes = JSON.parse(localStorage.getItem("emp"));
    this.occupationTypes = JSON.parse(localStorage.getItem("occ"));

    if (this.currentId) {
      await this.$store.dispatch(GET_EMPLOYMENT, this.currentId)
        .then(async (resp) => {
          //nfh-console.log('GET_EMPLOYMENT resp', resp);
          if (resp.success) {
            // this.employment = resp.data.data;
            //nfh-console.log('GET_EMPLOYMENT is SUCCESS');
          }
          else {
            this.emp = blankEmployment();
            //nfh-console.log('GET_EMPLOYMENT is FAILED - FILL WITH BLANK');
          }
        })
        .catch((err) => {
          //nfh-console.log('GET_EMPLOYMENT is FAILED:\n', err);
        });
    }
    //nfh-console.log('this.employment: ', this.employment);
    //nfh-console.log('OUT:CREATED');
  },

  mounted() {
    //nfh-console.log(' IN:MOUNTED');
    this.init();
    this.closeOverlay();
    //nfh-console.log('OUT:MOUNTED');
  },

  beforeDestroy() {
    //nfh-console.log(' IN:beforeDestroy');
    //nfh-console.log('OUT:beforeDestroy');
  },

}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.ucase-text input {
  text-transform: uppercase;
}

.v-text-field--outlined {
  font-size: 11pt;
}

.v-text-field--outlined label {
  font-size: 11pt;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 10pt;
}
</style>