import { render, staticRenderFns } from "./EditForm.vue?vue&type=template&id=0378425d&"
import script from "./EditForm.vue?vue&type=script&lang=js&"
export * from "./EditForm.vue?vue&type=script&lang=js&"
import style0 from "./EditForm.vue?vue&type=style&index=0&id=0378425d&lang=scss&"
import style1 from "./EditForm.vue?vue&type=style&index=1&id=0378425d&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBadge,VBtn,VCol,VIcon,VOverlay,VProgressCircular,VRow,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/home/fared/kowamas-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0378425d')) {
      api.createRecord('0378425d', component.options)
    } else {
      api.reload('0378425d', component.options)
    }
    module.hot.accept("./EditForm.vue?vue&type=template&id=0378425d&", function () {
      api.rerender('0378425d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/pages/form/EditForm.vue"
export default component.exports