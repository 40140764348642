<template>
  <div>
    <b-card-text>
      <div>
        <ValidationObserver ref="heirValidation">
          <v-form class="font-weight-bold font-size-sm">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12">
                  <h5 class="primary--text font-weight-bold">
                    Maklumat Waris
                  </h5>
                  <v-divider class="mt-0"></v-divider>
                </v-col>
                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider v-slot="{ errors }" name="Nama Pewaris" rules="required">
                    <v-text-field v-model="heir.heir_name" label="Nama Pewaris" hint="Nama seperti dalam Kad Pengenalan"
                      class="ucase-text" maxLength="128" :error-messages="errors" :readonly="isDisabled" outlined dense
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" name="No. Kad Pengenalan" rules="required">
                    <v-text-field v-model="heir.heir_nric" v-mask="mask_icNum" maxLength="14" :error-messages="errors"
                      :readonly="isDisabled" label="No. Kad Pengenalan" @change="computedInfoNRICHeir" outlined dense
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Tarikh Lahir"
                    rules="required"
                  >
                    <!-- <v-menu v-model="birthDateMenu" :error-messages="errors" :close-on-content-click="false"
                      :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }"> -->
                    <v-text-field
                      label="Tarikh Lahir"
                      hint="Tarikh Lahir diperoleh daripada Nombor Kad Pengenalan"
                      :value="computedHeirBirthDate"
                      :disable="isDisabled"
                      :error-messages="errors"
                      required
                      readonly
                      outlined
                      filled
                      dense
                    ></v-text-field>
                      <!-- v-model="heir.heir_birth_date" -->
                    <!-- </template>
                      <v-date-picker v-model="heir.heir_birth_date" :readonly="isDisabled" :disabled="isDisabled"
                        @input="birthDateMenu = false">
                      </v-date-picker>
                    </v-menu> -->
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="4">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Jantina"
                    rules="required"
                  >
                    <v-select
                      v-model="heir.heir_gender_id"
                      item-text="nameMS"
                      item-value="id"
                      label="Jantina"
                      :items="genders"
                      :error-messages="errors"
                      :readonly="isDisabled"
                      outlined
                      required
                      filled
                      dense
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Bangsa"
                    rules="required"
                  >
                    <v-select
                      v-model="heir.heir_race_id"
                      item-text="nameMS"
                      item-value="id"
                      label="Bangsa"
                      :items="races"
                      :error-messages="errors"
                      :readonly="isDisabled"
                      outlined
                      required
                      dense
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                >
                  <ValidationProvider v-slot="{ errors }" name="Agama" rules="required">
                    <v-select v-model="heir.heir_religion_id" :items="religions" item-text="nameMS" item-value="id"
                      :error-messages="errors" :readonly="isDisabled" label="Agama" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider v-slot="{ errors }" name="Hubungan" rules="required">
                    <v-select v-model="heir.relationship_id" :items="relations" item-text="nameMS" item-value="id"
                      :error-messages="errors" :readonly="isDisabled" label="Hubungan" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" md="12">
                  <h5 class="primary--text font-weight-bold">
                    Alamat Rumah
                  </h5>
                  <v-divider class="mt-0"></v-divider>
                </v-col>

                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider v-slot="{ errors }" name="Unit/Lot/No./Tingkat" rules="required">
                    <v-text-field hint="Contoh A1-2-3" v-model="heir.heir_unit" :error-messages="errors" maxLength="255"
                      :readonly="isDisabled" label="Unit/Lot/No./Tingkat" class="ucase-text" outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider v-slot="{ errors }" name="Jalan/Apartment/Taman" rules="required">
                    <v-text-field hint="Jalan Bahagia Kampung Sentosa" v-model="heir.heir_street" :error-messages="errors"
                      maxLength="255" :readonly="isDisabled" label="Jalan/Apartment/Taman" class="ucase-text" outlined
                      dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider v-slot="{ errors }" name="Butiran Tambahan 1" rules="">
                    <v-text-field hint="Masukkan butiran alamat sekiranya ada" v-model="heir.heir_line3" maxLength="255"
                      :error-messages="errors" :readonly="isDisabled" label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                      class="ucase-text" outlined dense>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="12">
                  <ValidationProvider v-slot="{ errors }" name="Butiran Tambahan 2" rules="">
                    <v-text-field hint="Masukkan butiran alamat sekiranya ada" v-model="heir.heir_line4" maxLength="255"
                      :error-messages="errors" :readonly="isDisabled" label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                      class="ucase-text" outlined dense></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="4">
                  <ValidationProvider v-slot="{ errors }" name="Poskod" rules="required">
                    <v-text-field v-model="heir.heir_postcode" :error-messages="errors" :readonly="isDisabled"
                      maxLength="5" v-mask="mask_postCode" label="Poskod" outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="8">
                  <ValidationProvider v-slot="{ errors }" name="Bandar" rules="required">
                    <v-select v-model="heir.heir_city_id" :items="city" item-text="display" item-value="id"
                      :error-messages="errors" :readonly="isDisabled" hint="Bandar mengikut poskod" label="Bandar"
                      outlined dense required v-on:change="onHeirCityChange">
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col class="py-0" cols="12" md="8">
                  <ValidationProvider v-slot="{ errors }" name="Negeri" rules="required">
                    <v-select v-model="heir.heir_state_id" :items="states" item-text="name" item-value="id"
                      :error-messages="errors" :readonly="isDisabled" label="Negeri" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" md="12">
                  <h5 class="primary--text font-weight-bold">
                    Telefon
                  </h5>
                  <v-divider class="mt-0"></v-divider>
                </v-col>

                <v-col class="py-0" cols="12" md="4">
                  <vue-tel-input-vuetify v-model="heir.heir_phone_num" :maxlength="13" :readonly="isDisabled"
                    placeholder="Telefon Bimbit" label="Telefon Bimbit" hint="Contoh: 010-123 4567 / 011-1234 5678"
                    defaultCountry="MY" :onlyCountries="['MY']" :background-color="inputColorHeirPhoneNum"
                    @input="onHeirPhoneNumInput" :translations="{
                      countrySelectorLabel: '',
                      countrySelectorError: '',
                      phoneNumberLabel: 'Telefon Bimbit',
                      example: 'Contoh :',
                    }" outlined dense required>
                  </vue-tel-input-vuetify>
                </v-col>

                <v-col class="py-0" cols="12" md="4">
                  <vue-tel-input-vuetify v-model="heir.heir_home_num" :maxlength="13" :readonly="isDisabled"
                    placeholder="Telefon Rumah" label="Telefon Rumah" hint="Contoh: 03-1234 5678 / 04-123 4567"
                    defaultCountry="MY" :onlyCountries="['MY']" :background-color="inputColorHeirHomeNum"
                    @input="onHeirHomeNumInput" :translations="{
                      countrySelectorLabel: '',
                      countrySelectorError: '',
                      phoneNumberLabel: 'Telefon Rumah',
                      example: 'Contoh :',
                    }" outlined dense required>
                  </vue-tel-input-vuetify>
                </v-col>

                <v-col class="py-0" cols="12" md="4">
                  <vue-tel-input-vuetify v-model="heir.heir_office_num" :maxlength="13" :readonly="isDisabled"
                    placeholder="Telefon Pejabat" label="Telefon Pejabat" hint="Contoh: 03-1234 5678 / 04-123 4567"
                    defaultCountry="MY" :onlyCountries="['MY']" :background-color="inputColorHeirOfficeNum"
                    @input="onHeirOfficeNumInput" :translations="{
                      countrySelectorLabel: '',
                      countrySelectorError: '',
                      phoneNumberLabel: 'Telefon Pejabat',
                      example: 'Contoh :',
                    }" outlined dense required>
                  </vue-tel-input-vuetify>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
      </div>
    </b-card-text>

    <div class="pr-3 pl-3 pb-2 pt-10" style="display: flex; align-items: flex-end; flex-direction: column;">
      <v-btn v-if="!isDisabled" class="ml-4" color="primary" :loading="flagLoading" @click="save()">
        <span class="font-weight-bold">Simpan</span>
      </v-btn>
    </div>

    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" elevation="24" top>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="1">
          <v-icon>{{ snackbar.icon }}</v-icon>
        </v-col>
        <v-col cols="10">
          <div class="mx-3">
            {{ snackbar.title }}
            <p class="mb-0">
              {{ snackbar.text }}
            </p>
          </div>
        </v-col>
        <v-col cols="1">
          <v-btn icon @click="snackbar.visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>

    <ErrorMsgDialog v-model="showErrorMsgDialog" title="Ralat" :content="msgERR.message" :showProceedButton="true"
      :showBackButton="false" :delayOnProceed="false" :isTypeError="true" proceedButton="OK"></ErrorMsgDialog>

    <InfoMsgDialog v-model="showInfoMsgDialog" title="Info" :content="msgINFO.message" :showProceedButton="true"
      :showBackButton="false" :delayOnProceed="false" proceedButton="OK"></InfoMsgDialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import dayjs from "dayjs";

import {
  mapGetters,
} from "vuex";

import {
  SET_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module";

// import {
// } from "@/core/services/store/form.module";

import {
  CREATE_HEIR,
  UPDATE_HEIR,
  GET_HEIR,
} from "@/core/services/store/application.module";

import {
  required,
  max,
  numeric,
} from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import {
  blankHeir,
  convUpperCase,
  extractInfoNRIC,
} from "@/core/services/helper.service";

// import ErrorMsgDialog from "@/view/pages/widgets/MessageDialog.vue";
// import InfoMsgDialog from "@/view/pages/widgets/MessageDialog.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} melebihi had maksima {length} aksara",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});


export default {
  name: "HeirDetails",

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    propReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      theData: blankHeir(),

      city: [],
      states: [],
      occupationTypes: [],
      cityOfficeScoped: [],
      cityHeirScoped: [],

      mask_icNum: "######-##-####",
      mask_postCode: "#####",
      birthDateMenu: false,
      overlay: true,

      flagLoading: false,
      showErrorMsgDialog: false,
      showInfoMsgDialog: false,

      phone: {
        heir_phone_num: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },

        heir_home_num: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },

        heir_office_num: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },
      },

      snackbar: {
        icon: "",
        color: "",
        title: "",
        text: "",
        visible: false,
      },

      msgERR: {
        message: "",
        nric: "",
        run: 0,
      },

      msgINFO: {
        message: "",
        nric: "",
        run: 0,
      },

      inputColorOK: "cyan lighten-5",
      inputColorNG: "red lighten-4",
      exceptionList: ["email", "employer_email", "financing_calc", "signature"],
    }
  },

  watch: {
  },

  computed: {
    ...mapGetters([
      "application",
      "currentUser",
      "currentUserRoles",
    ]),

    heir: {
      get: function () {
        return this.theData;
      },

      set: function (val) {
        this.theData = val;
        //nfh-console.log('COMPUTED val, this.theData: ', val, this.theData);
      }
    },

    currentId() {
      return parseInt(this.$route.params.id) ?? 0;
    },

    computedHeirBirthDate() {
      //nfh-console.log("computedHeirBirthDate : ", dayjs(this.heir.heir_birth_date).format("DD/MM/YYYY"));
      return this.heir.heir_birth_date
        ? dayjs(this.heir.heir_birth_date).format("DD/MM/YYYY")
        : "";
    },

    inputColorHeirPhoneNum() {
      return this.phone.heir_phone_num.color;
    },

    inputColorHeirHomeNum() {
      return this.phone.heir_home_num.color;
    },

    inputColorHeirOfficeNum() {
      return this.phone.heir_office_num.color;
    },

    isDisabled() {
      return !!this.propReadOnly;
    },

    isLoadingDSR() {
      return !!this.flagLoading;
    }
  },

  methods: {
    async onValidate() {
      return new Promise((resolve, reject) => {
        this.$refs.heirValidation.validate()
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
      });
    },

    enableLoading() {
      this.flagLoading = true;
    },

    disableLoading() {
      this.flagLoading = false;
    },

    showSnackbarSuccess(message = "", title = "") {
      if (message != "") {
        this.snackbar.icon = "mdi-check-circle";
        this.snackbar.color = "success";
        this.snackbar.title = title;
        this.snackbar.text = message;
        this.snackbar.visible = true;
      }
    },

    showSnackbarFailure(message = "", title = "") {
      if (message != "") {
        this.snackbar.icon = "mdi-close-circle";
        this.snackbar.color = "red";
        this.snackbar.title = title;
        this.snackbar.text = message;
        this.snackbar.visible = true;
      }
    },

    showSnackbarReset() {
      this.snackbar.icon = "";
      this.snackbar.color = "";
      this.snackbar.title = "";
      this.snackbar.text = "";
      this.snackbar.visible = false;
    },

    computedInfoNRICHeir() {
      const _resp = extractInfoNRIC(this.heir.heir_nric);
      //nfh-console.log('computedInfoNRICHeir: ', _resp);
      this.heir.heir_birth_date = _resp.birth_date;
      this.heir.gender_id       = _resp.gender_id;
      this.heir.salute_id       = _resp.salute_id;
    },

    onHeirPhoneNumInput(formattedNumber, { number, valid, country }) {
      this.phone.heir_phone_num.country = country;
      this.phone.heir_phone_num.number  = number.national;
      this.phone.heir_phone_num.mobile  = parseInt(this.phone.heir_phone_num.number.split('-')[0]) > 9;
      this.phone.heir_phone_num.valid   = valid && this.phone.heir_phone_num.mobile;
      this.phone.heir_phone_num.color   = (this.phone.heir_phone_num.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onHeirHomeNumInput(formattedNumber, { number, valid, country }) {
      this.phone.heir_home_num.country = country;
      this.phone.heir_home_num.number  = number.national;
      this.phone.heir_home_num.mobile  = parseInt(this.phone.heir_home_num?.number.split('-')[0]) > 9;
      this.phone.heir_home_num.valid   = valid && !this.phone.heir_home_num.mobile;
      this.phone.heir_home_num.color   = (this.phone.heir_home_num.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onHeirOfficeNumInput(formattedNumber, { number, valid, country }) {
      this.phone.heir_office_num.country = country;
      this.phone.heir_office_num.number  = number.national;
      this.phone.heir_office_num.mobile  = parseInt(this.phone.heir_office_num.number.split('-')[0]) > 9;
      this.phone.heir_office_num.valid   = valid && !this.phone.heir_office_num.mobile;
      this.phone.heir_office_num.color   = (this.phone.heir_office_num.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onHeirCityChange(value) {
      if (
        this.city.find((ct) => {
          if (ct.id == value) {
            this.heir.heir_state_id = ct.state_id;
          }
        })
      );
    },

    onHeirStateChange(value) {
      this.cityHeirScoped = value
        ? this.city.slice().filter((ct) => ct.state_id === value)
        : this.city.slice();
    },

    save() {
      if (!this.isDisabled) {
        this.openOverlay();

        this.$refs.heirValidation.validate()
          .then((success) => {
            if (success) {
              this.enableLoading();
              convUpperCase(this.heir, this.exceptionList);

              setTimeout(() => {
                //nfh-console.log('this.heir: ', this.heir);

                this.$store.dispatch(UPDATE_HEIR, this.heir)
                  .then((resp) => {
                    if (resp.success) {
                      this.showSnackbarSuccess("Maklumat Waris telah disimpan.");
                    }
                    else {
                      this.showSnackbarFailure(resp.message);
                    }
                    this.disableLoading();
                    this.closeOverlay();
                  })
                  .catch((err) => {
                    this.promptError(err);
                    this.closeOverlay();
                  })
                  .finally(() => {
                    this.disableLoading();
                    this.closeOverlay();
                  });
              }, 200);
            }
            else {
              this.closeOverlay();
              this.showSnackbarFailure("Sila lengkapkan ruangan data wajib.", "Data Tidak Lengkap");
              this.goToError(this.$refs.heirValidation);
            }
          });
      }
    },

    goToError(_ref) {
      setTimeout(() => {
        const errors = Object.entries(_ref.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);

        _ref.refs[errors[0]["key"]]?.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    },

    promptError(message) {
      this.msgERR.message = errorMessages(message);
      this.showErrorMsgDialog = true;
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 200);
    },
  },

  async created() {
    //nfh-console.log(' IN:CREATED: ', this.currentId);

    this.city      = JSON.parse(localStorage.getItem("cty"));
    this.races     = JSON.parse(localStorage.getItem("rce"));
    this.states    = JSON.parse(localStorage.getItem("stt"));
    this.genders   = JSON.parse(localStorage.getItem("gdr"));
    this.religions = JSON.parse(localStorage.getItem("rgn"));
    this.relations = JSON.parse(localStorage.getItem("rel"));

    if (this.currentId) {
      await this.$store.dispatch(GET_HEIR, this.currentId)
        .then(async (resp) => {
          //nfh-console.log('GET_HEIR resp', resp);
          if (resp?.success) {
            this.heir = resp?.data;
            //nfh-console.log('GET_HEIR is SUCCESS');
          }
          else {
            this.heir = blankHeir();
            //nfh-console.log('GET_HEIR is FAILED - FILL WITH BLANK');
          }
        })
        .catch((err) => {
          //nfh-console.log('GET_HEIR is FAILED:\n', err);
        });
    }
    //nfh-console.log('this.heir: ', this.heir);
    //nfh-console.log('OUT:CREATED');
  },

  mounted() {
    //nfh-console.log(' IN:MOUNTED');
    this.closeOverlay();
    //nfh-console.log('OUT:MOUNTED');
  },

  beforeDestroy() {
    //nfh-console.log(' IN:beforeDestroy');
    //nfh-console.log('OUT:beforeDestroy');
  },

}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.ucase-text input {
  text-transform: uppercase;
}

.v-text-field--outlined {
  font-size: 10pt;
}

.v-text-field label {
  font-size: 10pt;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 10pt;
}
</style>